
/* Testimonials Start */
.teals-testimonials .carousel {
        position: relative;
        overflow: hidden;
        width: -webkit-fill-available;
        /* height: 800px; */
        /* margin: 0px 40px; */
}
.teals-testimonials {
    position: relative;
    overflow: visible;
    background-size: 100% 100%;
    padding: 100px 0px 0px;
    /* padding-bottom: 70px; */
    /* background-image: url(../assets/testimonials.png); */
    background-size: contain;
    background-position: right;
}
.testimonial-title p {
    margin-bottom: auto;
    font-size: 20px;
    color: black;
    padding: 0px 74px;
}
.teals-testimonials .background-label {
    position: absolute;
    color: rgba(29, 30, 49, 0.02);
    top: 75px;
    left: -2.5%;
    font-size: calc(150px + 300 * ((100vw - 320px) / 1600));
    line-height: 0.8;
    font-family: GilroyBold, sans-serif;
    font-weight: normal;
    letter-spacing: calc(27px + 22 * ((100vw - 320px) / 1600));
    z-index: -1;
}

.teals-testimonials span.bubble-title {
    position: relative;
    background-color: black;
    color: white;
    font-size: calc(18px + 6 * ((100vw - 320px) / 1600));
    line-height: calc(41px + 10 * ((100vw - 320px) / 1600));
    font-family: GilroyRegular, SansSerif;
    font-weight: normal;
    display: inline-block;
    margin-bottom: 32px;
    padding: 0 20px;
    letter-spacing: 0.02em;
}
.teals-testimonials span.bubble-title:after {
    content: "";
    position: absolute;
    bottom: -21px;
    left: 60px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
    border-width: 22px 24px 0 2px;
}
.teals-testimonials .testimonial-head {
    display: flex;
    justify-content: space-between;
}
.testimonial-title h2 {
    text-transform: uppercase;
    font-size: 50px;
    color: black;
    margin-bottom: 0;
}
.testimonial-title h2>span {
    color: #24e3c5;
}

.teals-testimonials .testimonial-head .testimonial-btn {
    display: flex;
    gap: 15px;
}

.teals-testimonials .client_review .review_lb, .teals-testimonials .client_review .review_sb {
    position: relative;
    background: rgba(255, 255, 255, 0.8) 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.051);
    border-radius: 20px;
    padding: 30px 30px 60px;
    margin-bottom: 16px;
    min-height: 360px;
}
.teals-testimonials .client_review .review_lb img, .teals-testimonials .client_review .review_sb img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    box-shadow: 0px 10px 10px #000 0d;
    border: 2px solid white;
    border-radius: 60px;
    margin-right: 20px;
}
.teals-testimonials .client_review .review_lb h3, .teals-testimonials .client_review .review_sb h3 {
    font:   GilroySemiBold, SansSerif;
    letter-spacing: 0px;
    color: #1e1e1e;
    margin-bottom: 0px;
}
.client-reviwe-sec {
    font:   GilroySemiBold, SansSerif;
    letter-spacing: 0px;
    color: #1e1e1e;
    margin-bottom: 0px;
}
.teals-testimonials .client_review .review_lb h4, .teals-testimonials .client_review .review_sb h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    color: #4a4b49;
    margin-bottom: 0;
}
.review_lb .align-at-bottom, .review_sb .align-at-bottom {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    color: #4a4b49;
    margin-bottom: 0;
}
.media-body .desgination-client{
    
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px !important;
    color: #4a4b49 !important;
    margin: 0 !important;
}    
.review_lb .align-at-bottom span{
    color: #295e76;
    margin-right: 10px;
}

.teals-testimonials .client_review .review_lb h4 span, .teals-testimonials .client_review .review_sb .align-at-bottom span {
    color: #295e76;
    margin-right: 10px;
}
.teals-testimonials .client_review .review_lb .align-at-bottom, .teals-testimonials .client_review .review_sb .align-at-bottom {
    position: absolute;
    bottom: 30px;
}

.teals-testimonials .client_review .review_lb p, .teals-testimonials .client_review .review_sb p {
    position: relative;
    letter-spacing: 0px;
    color: black;
    margin: 30px 0 20px;
}

.teals-testimonials .client_review .review_lb .play_video, .teals-testimonials .client_review .review_sb .play_video {
    position: absolute;
    right: 2%;
    top: 7%;
    width: auto;
    height: auto;
    border: none;
    padding: 0;
    margin: 0;
    outline: none !important;
    box-shadow: none;
    background-color: transparent;
}
.teals-testimonials .client_review .review_lb .play_video .modal-play-btn, .teals-testimonials .client_review .review_sb .play_video .modal-play-btn {
    width: auto;
    height: auto;
    object-fit: inherit;
    box-shadow: none;
    border: 0px;
    border-radius: 0px;
    margin-right: 0px;
}
.teals-testimonials .testimonials-main {
    margin-top: 48px;
}

.review_right {
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 70px;
    padding-right: 30px;
}

.review_right p {
    font-size: 21px;
    line-height: 1.7;
    margin: 0;
}

.teals-testimonials-new-sec .testimonial-head-new {
    align-items: flex-end;
}
.teals-testimonials-new-sec .testimonial-head-new .testimonial-btn {
    position: relative;
    z-index: 1;
    right: 20px;
    bottom: 10px;
    display: block !important;
}

.testimonials_web .img-box {
    width: 200px;
}

.testimonials_web {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
    border-radius: 20px;
    color: white;
    padding: 28px 29px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: 2px solid #24e3c5;
}

.testimonials_web h3 {
    color: #24e3c5;
}
.testimonials_web p{
    color: black;
}
.testimonials_web .stars svg {
    color: #FFC700;
    font-size: 30px;
}
.testimonials_web .stars {
    display: flex;
    gap: 13px;
}

/* .swiper-backface-hidden .swiper-slide {
    width: 660px !important;
} */