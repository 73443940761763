@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Inter:wght@200;300;400;500;600;700&display=swap');
@import url('https://db.onlinewebfonts.com/c/ad230925aac0325de699bf05b6bdfa0c?family=LTC+Record+Title+W00+Regular');
@font-face {
  font-family: 'Agrandir Regular';
  src: url('./component/assets/fonts/Agrandir-Regular.otf') format('truetype');
}
div#root {
  background: #ffff;
  overflow: hidden;
}

body {    
  margin: 0;
  letter-spacing: 1px;
  font-family: Agrandir Regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #24e3c5;
}
nav.navbar.navbar-expand-lg.navbar-light{
  /* position: fixed; */
  width: 100%;
  z-index: 99;
}
.navbar-expand-lg .navbar-nav .nav-link:hover{
  color: #000;
}

.mainSlider .box-service:hover h3 {
  color: #fff;
}
.mainSlider .box-service:hover {
  transform: scale(0.85); 
  cursor: pointer;
  border-color: white;
  transition-duration: .5s;
  background: #24E3C5;
}
.mainSlider .box-service {
  background: white;
  padding: 20px;
  height: 200px;
    border-radius: 41px;
    border: 5px solid #24E3C5;
    justify-content: space-between;
    align-items: center;
    display: flex !important;
    flex-direction: column;
}
.navbar-expand-lg .navbar-nav .nav-link{
  color: black;
  position: relative;
}
.pullRight a:before
{
    position: absolute;
    width: 2px;
    height: 100%;
    left: 0px;
    top: 0px;
    content: '';
    background: #44e4c4;
    opacity: 0.3;
    transition: all 0.3s;
}


.pullRight a:hover:before
{
    width: 100%;
}
/* Pull right and left  */
.pullRightLeft a:before, div.pullRightLeft a:after
{
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0px;
    content: '';
    background: #FFF;
    opacity: 0.3;
    transition: all 0.3s;
}

.pullRightLeft a:before
{
    left: 0px;
} 

.pullRightLeft a:after
{
    right: 0px;
}

.pullRightLeft a:hover:before, div.pullRightLeft a:hover:after
{
    width: 100%;
}


.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
  width: 100%;justify-content: flex-end;
  gap: 10px;
}
.triangle-down {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 12px solid #24E3C5;
}
a.hoverlink.none-for-res.nav-link {
  display: flex;
  align-items: center;
  gap: 4px;
}
/* .bg-sec{
  background: url(./component/assets/banner.mp4);
  background-size: contain;
  height: 80vh;
  display: flex;
  align-items: end;
  justify-content: flex-start;
  padding: 0px 0px 42px;
} */
.video-container {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  background: url(./component/assets/banner.mp4);
}
.mainSlider {
  transform: scale(0.9) translate(0px, -95px);
  background: #ffffff00;
}

.videoTag {
  width: 100%;
  height: auto;
}
.videoTag1 {
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.overlay {    
 
  position: absolute;
  background-color: #24e3c5bd;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px;
  background-size: contain;
  padding-top: 210px;
}
.container.fjffjf{

  height: inherit;
}
.overlay .second-para{
  color: white;
  text-align: left;
  font-size: 26px;
  margin: auto;
}
.overlay .second-para button {
  text-align: center;
  justify-content: center;
  padding: 16px 24px;
  width: 44%;
  border-color: #05584b;
  border-width: 7px;
  border-radius: 101px;
}
.overlay .second-para h1 {
  text-transform: uppercase;
  text-align: center;
  color: #CAFFF7;
}
.overlay .second-para P {
  text-transform: capitalize;
  text-align: center;
  color: #CAFFF7;
}
.overlay .second-para span {
  color: #05584b;
  font-weight: 700;
}
.text-center.toggle {
  margin-top: 66px;
}
.text-center.toggle span svg {
  font-size: 58px;
  color: #05584b;
  border: 5px solid #05584b;
    border-radius: 500px;
}
.bg-sec h2.heading {
  font-size: 55px;
  color: white;
  text-align: left;
  margin-bottom: auto;
  font-weight: 700;
  font-style: normal;
  line-height: 18px;
}
.bg-sec .second-para .portfolio-heading {
  font-size: 50px;
  color: white;
  text-align: center;
  margin-bottom: auto;
  font-weight: 600;
  font-style: normal;
}
.bg-sec .second-para .portfolio-p  {
  font-size: 30px;
  color: white;
  text-align: center;
  margin-bottom: auto;
  font-weight: 500;
  font-style: normal;
}
.texter {
  padding-left: 168px;
  margin-top: 43px;
}
.bg-sec h4 {
  font-size: 152px;
  color: white;
  text-align: left;
}
.bg-sec button {
  display: flex;
    align-items: center;
    padding: 0px 0px;
    background: none;
    border: 1px solid white;
    color: white;
    justify-content: space-between;
    width: 165px;
    font-family: "Inria Serif", serif;
    font-weight: 800;
    font-size: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.bg-sec .jhvb{
  padding: 8px 15px;
  width: -webkit-fill-available;
  border-right: 1px solid white;
}
.bg-sec button .arrow {    padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sec-two{
  /* background: url(./component/assets/bgsecfrn.png); */
padding: 100px 0px;
background-size: cover;
}
.mainSlider .box-service h3 {
  font-size: 22px;
  margin-top: 15px;
  color: #24E3C5;
  font-weight: 700;
}
/* .sec-two .swiper-main-div {
  width: 400px !important;
} */
.sec-two .swiper-main-div img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.hoverable-new .innerbox1 .innerbox-checkbox-sec .innerbox-checkbox:before {
  background: 0 0;
  border: 1.5px solid #00e2c5;
  border-radius: 0 8px 0 0;
  height: 16px;
  left: 0;
  top: 6px;
  transform: translateY(0);
  transition: all .3s linear;
  width: 16px;
  content: '';
}
.hoverable-new .innerbox2 .innerbox-checkbox-sec .innerbox-checkbox:before {
  background: 0 0;
  border: 1.5px solid #00e2c5;
  border-radius: 0 8px 0 0;
  height: 16px;
  left: 0;
  top: 6px;
  transform: translateY(0);
  transition: all .3s linear;
  width: 16px;
  content: '';
}
.hoverable-new .innerbox1 .innerbox-checkbox-sec .innerbox-checkbox:hover:before {
  background: #00e2c5;
  transition: all .3s linear;
}
.hoverable-new .innerbox2 .innerbox-checkbox-sec .innerbox-checkbox:hover:before {
  background: #00e2c5;
  transition: all .3s linear;
}
.hoverable-new .innerbox1 .innerbox-checkbox-sec .innerbox-checkbox label{
  cursor: pointer;
}
.hoverable-new .innerbox1 .innerbox-checkbox-sec .innerbox-checkbox:hover{
color: #00e2c5;
cursor: pointer;
}
.hoverable-new .innerbox2 .innerbox-checkbox-sec .innerbox-checkbox:hover{
color: #00e2c5;
cursor: pointer;
}
.swiper-main-div img {
  transition: transform 0.5s ease; /* Smooth sliding effect */
  width: 100%; /* Adjust image width */
  transform: rotateY(180deg); /* Flips the image horizontally */
}

.skidesec2 .swiper-slide{
  
  width: auto !important;
}
.skidesec2 {
  overflow: hidden; /* Optional: Hide any overflow */
}

.sec-two .text-box{
  color: white;
  padding: 10px 185px;
  margin-top: 50px;
}
.sec-two .text-box h2>span{
  color: #24e3c59e;
}
.sec-two .text-box h2{
  
  font-size: 50px;
  color: #24e3c5;
  /* font-family: Inter; */
  text-transform: uppercase;
}
.sec-two .text-box p {
  color: #4f4f4f;
  font-size: 24px;
}
.count-box {
  display: flex;
  gap: 20px;
  color: white;
  align-items:center;
}
.count-box span {
  font-size: 110px;
  color: #fff;
}
.count-box p>span {
  color: #fff;
  font-size: larger;
}
.count-box p {
    margin-bottom: inherit;
    font-size: 33px;
    text-align: left;
    line-height: 38px;
    color: #24e3c59e;
}
.sec-two .hearticon {
  display: flex;
  justify-content: end;
  align-items: end;
}
.sec-two .hearticon svg{
  color: #DAB35D;
  background: transparent;
  font-size: 24px;
}
.product-box {
  background: white;
  padding: 18px 11px;
  border-radius: 14px;
  height: 300px;
}
.product-box img {
  object-fit: contain;
  width: 90%;
  /* height: 100%; */
}
.properties {
  text-align: left;
  padding-top: 9px;
}
.properties h3 {
  color: #dab25d;
  font-family: "Inria Serif", serif;
  font-weight: 700;
  font-size: 26px;
  display: flex;
  margin: 0px;
  align-items: center;
  justify-content: space-between;
}
.properties h3 span {
  color: white;
  font-size: 20px;
}
.properties p {
  color: white;
  margin: 0px;
  font-size: 18px;
}
.cart-button {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px 0px;
}
.cart-button button {
  padding: 7px 23px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: #dab25d;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 18px;
}
.third-sec {
  padding: 46px 0px;
}

.third-sec .img-box {
  text-align: center;
  position: relative;
  z-index: 999;
}

.third-sec .img-box .shadow-img {
  height: 300px;
  width: 300px;
  border-radius: 100px;
  margin-bottom: 20px; /* Space below the image */
}

.third-sec .thrd-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  color: white;
}

.shopnow {
  display: flex;
  align-items: center;
}

.shopnow button {
  background-color: #ffcc00; /* Customize as needed */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  margin-right: 20px;
  cursor: pointer;
}

.shopnow h3 {
  font-size: 31px;
  color: white;
  font-family: "LTC Record Title W00 Regular";
  font-weight: 500;
}
.shadow-box {
  box-shadow: 2px -3px 20px 20px rgb(145 139 139 / 10%);
  height: 0px;
  background: #ffffff03;
  border-radius: 109px;
  width: 362px;
  border: 0px solid rgb(145 139 139 / 27%);
  padding: 99px;
  background: rgb(145 139 139 / 10%);
  margin-top: -169px;
  position: relative;
  z-index: 99;
}
.shfhfj{
  display: flex;
  align-items: center;
flex-direction: column;
  justify-content: center;
}
.thrd-text-box h2 {
  font-size: 57px;
}
.thrd-text-box p {
  font-size: 14px;
}
.shopnow button {
  background: #5D4741;
  color: white;
  padding: 10px 25px;
}
.mainHeading span.f-span {
  width: 155px;
  /* box-shadow: -7px 0px 1px 1px gray; */
  border-image-source: linear-gradient(270deg, #ededed 0%, rgb(244 244 244 / 0%) 100%);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  height: 2px;
  background: linear-gradient(329deg, #FFF 0%, rgb(255 255 255 / 0%) 141%);
}
.mainHeading span.s-span {
  width: 155px;
  /* box-shadow: -7px 0px 1px 1px gray; */
  border-image-source: linear-gradient(79deg, #ededed 0%, rgb(244 244 244 / 0%) 100%);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  height: 2px;
  background: linear-gradient(136deg, #FFF 0%, rgb(255 255 255 / 0%) 141%);
}
.mainHeading svg{
  color: white;
  font-size: 28px;
}
.mainHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.headingsForth p{
  font-size: 18px;
  color: white;
  margin: 0px;
}
.headingsForth h2{
  color: white;
  font-size: 55px;
  margin: 0px;
}
.teals-testimonials-new-sec .swiper-slide img {
  width: 100%;
  height: auto;
  position: relative;
  display: table-footer-group;
  /* border-radius: 10px; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 6px solid #24e3c59e;
  border-radius: 20px;
}
.teals-testimonials-new-sec .swiper{
  overflow: unset;
}
.teals-testimonials-new-sec .swiper-slide.swiper-slide-active:after {
  width: 200px;
  height: 200px;
  /* content: url('./component/assets/daimond.png'); */
  position: absolute;
  /* border: 20px solid black !important; */
  bottom: 0%;
    right: -17px;

}
.teals-testimonials-new-sec .swiper-slide h3 {
  color: white;
  font-size: 37px;
  margin-top: 20px;
}
section.forth-sec{
  transform: translate(0px, 125px);
}

.sec-fifth{
  background-color: #232323;
    padding: 100px 0px;
    background-size: contain;
}
.sec-fifth .text-box{
  color: white;
  text-align: center;
}
.sec-fifth .text-box h2>span{
  color: #24e3c59e;
}
.sec-fifth .text-box h2{
  font-size: 50px;
  color: #fff;
}
.sec-fifth .text-box p {
  font-size: 18px;
  color: white;
}
.sec-fifth .hearticon {
  display: flex;
  justify-content: end;
  align-items: end;
}
.sec-fifth .hearticon svg{
  color: #DAB35D;
  background: transparent;
  font-size: 24px;
}

/* box new */
.hoverable-new {
  transition: transform 0.9s ease, border-radius 0.9s ease;
}
.hoverable-new .innerbox1, .hoverable-new .innerbox2 {
  padding: 20px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.hoverable-new .innerbox1 {
  border: 5px solid #24e3c5;
  border-top-right-radius: 100px;
}
.hoverable-new:hover .innerbox1, .hoverable-new:hover .innerbox2 {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-top-right-radius: 50px;
}
.hoverable-new .innerbox1 h3 {
  color: #4f4f4f;
  font-size: 40px;
  font-weight: 400;
}
.hoverable-new .innerbox1 p, .hoverable-new .innerbox2 p {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.hoverable-new .innerbox1 p {
  color: #4f4f4f;
  font-size: 20px;
  font-weight: 300;
  opacity: 0;
  text-transform: capitalize;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease,   border-top-right-radius 100px;
}

.hoverable-new .innerbox1 p {
  opacity: 1;
  transform: translateY(0);
}
.hoverable-new .innerbox1 .innerbox-checkbox-sec {
  margin-top: 15px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 16px 3px;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  opacity: 0;
  opacity: 1;
  transform: translateY(0);
  margin: auto;
}
.hoverable-new .innerbox1:hover .innerbox-checkbox-sec {
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.hoverable-new .innerbox1 .innerbox-checkbox-sec .innerbox-checkbox {
  width: fit-content;
  display: flex;
  gap: 10px;
  align-items: center;
  color: inherit;
  text-decoration: none;
}
.hoverable-new .innerbox1 {
  height: 500px;
  display: inline-flex;
  padding-bottom: 22px;
  flex-direction: column;
  cursor: pointer;
  width: -webkit-fill-available;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
}
.hoverable-new:hover .innerbox1 p, .hoverable-new:hover .innerbox2 p {
  opacity: 1;
  transform: translateY(0);
}
.hoverable-sec1 {
  width: 63%;
  cursor: pointer;
  transition: width 2.5s ease; /* Smooth transition */
}
.hoverable-new .hoverable-sec1{
  overflow: hidden;
}
/* box2 new */
.hoverable-sec2 {
  width: 40%;
}
.hoverable-sec2:hover {
  width: 100%;
}
/* hoverable-sec2 by default with visible radio buttons */
.hoverable-sec2 .innerbox-checkbox-sec {
  display: flex; /* Ensures it shows by default */
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
/* Default styles for both sections */
.hoverable-new .innerbox-checkbox-sec {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.3s ease;
}

/* Hide innerbox-checkbox-sec of hoverable-sec1 when hoverable-sec2 is hovered */
.hoverable-sec2:hover ~ .hoverable-sec1 .innerbox-checkbox-sec {
  opacity: 0;
  transform: translateY(-20px);
}

/* Hide the inner checkboxes on hover */
.hoverable-sec2:hover .innerbox-checkbox-sec {
  display: none;
}
.hoverable-sec2 {

  /* transition: width 0.5s ease; */
}


/* Other styles remain unchanged */

/* When hovering on hoverable-sec2, we hide the other sections */
.hoverable-new .hoverable-sec2:hover .innerbox-checkbox-sec {
  display: none;
}
.hoverable-new .hoverable-sec2:hover .innerbox-checkbox-sec .innerbox-checkbox {
  display: none;
}

/* Styling to ensure hoverable-sec2 works as expected */
.hoverable-new .innerbox2:hover ~ .innerbox1 .innerbox-checkbox-sec {
  opacity: 0 !important;
  transform: translateY(-20px); /* Adjust as needed for visibility */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable-new .innerbox2 {
  background-color: #232323;
  border-top-right-radius: 100px;
  transform: translateX(10px);
  transition: opacity 1.5s ease, transform 0.5s ease;
}
.hoverable-new:hover .innerbox-checkbox-sec {
  opacity: 1;
  transform: translateY(0);
}
.hoverable-new:hover .innerbox2 {
  /* width: 900px; */
  opacity: 1;
  transform: scale(1.05); /* scale up the element */
  transition: transform 0.5s ease-in-out, width 0.3s ease-in-out; /* increased duration and added easing */
}

.hoverable-new .innerbox2:hover ~ .innerbox1 .innerbox-checkbox-sec {
  opacity: 0;
  transform: translateY(-20px); /* Adjust as needed for visibility */
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.hoverable-new .innerbox2 h3 {
  color: #24e3c5;
  font-size: 40px;
  font-weight: 400;
}

.hoverable-new .innerbox2 p {
  color: #ffff;
  font-size: 20px;
  opacity: 0;
  text-transform: capitalize;
  font-weight: 300;
  transform: translateX(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable-new .innerbox2 p {
  opacity: 1;
  transform: translateX(0);
}
.hoverable-new .innerbox2 .innerbox-checkbox-sec {
  margin-top: 15px;
  color: #ffff; 
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex-wrap: wrap;
  justify-content: space-between;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.hoverable-new .innerbox2:hover .innerbox-checkbox-sec {
  opacity: 1;
  transform: translateY(0);
}
.hoverable-new .innerbox2 .innerbox-checkbox-sec .innerbox-checkbox label{
  cursor: pointer;
  text-decoration: none;
}
.hoverable-new .innerbox2 .innerbox-checkbox-sec .innerbox-checkbox {
  text-decoration: none;
  color: white;
  width: fit-content;
  display: flex;
  gap: 10px;
  align-items: center;
}
.innerbox-checkbox-sec a {
  text-decoration: none;
  color: #fff;
}
.testimonials_web {
  /* transition: transform 0.5s ease;  */
  transform: scale(0.9); /* Zoom out to 90% of the original size */

}
.hoverable-new .innerbox2 {
  height: 500px;
  display: inline-flex;
  padding-bottom: 22px;
  flex-direction: column;
  cursor: pointer;
  width: -webkit-fill-available;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
}

/* box1 */
.hoverable .innerbox1 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox1 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox1 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox1 {
  background: url('./component/assets/innerbox1.png');
  height: 300px;
  display: inline-flex;
  justify-content: end;
  padding-bottom: 22px;
  flex-direction: column;
  cursor: pointer;
  width: -webkit-fill-available;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
}
.hoverable:hover .innerbox1 {
  width: 500px;
}
.modal-content {
  background: #0000008f !important;
  backdrop-filter: blur(10px);
  box-shadow: 0px 2px 4px 1px #24e3c59e;
}
.modal-content .modal-header {
  border: none;
  color: white;
}
.modal-content .modal-body {
  border: none;
  color: white;
}
.modal-content .modal-footer {
  border: none !important;
  justify-content: center;
  transform: translate(0px, 45px);
}
.modal-title.h4 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.modal-title.h4 svg {
  color:#24e3c59e;
  cursor: pointer;
}
button.talk-button.btn.btn-secondary {
  background: #24e3c59e;
  border: 3px solid #24e3c59e;
  border-radius: 17px;
}
/* box 2 */
.hoverable .innerbox2 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox2 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox2 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox2 {
  background: url('./component/assets/box2.png');
  height: 300px;
    background-size: 100%;
  display: inline-flex;
  justify-content: end;
  cursor: pointer;
  padding-bottom: 22px;
  flex-direction: column;
  width: -webkit-fill-available;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
}
.hoverable:hover .innerbox2 {
  width: 500px;
}

/* box3 */
.hoverable .innerbox3 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox3 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox3 {
  width: 500px;
}


.hoverable:hover .innerbox3 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox3 {
  background: url('./component/assets/box3.png');
  height: 300px;
    background-size: 100%;
  display: inline-flex;
  justify-content: end;
  padding-bottom: 22px;
  cursor: pointer;
  flex-direction: column;
  width: -webkit-fill-available;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
}

/* box4 */
.hoverable .innerbox4 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox4 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox4 {
  width: 500px;
}


.hoverable:hover .innerbox4 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox4 {
  background: url('./component/assets/box4.png');
  height: 300px;
    background-size: 100%;
  display: inline-flex;
  justify-content: end;
  padding-bottom: 22px;
  background-size: 100%;
  cursor: pointer;
  flex-direction: column;
  width: -webkit-fill-available;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
}

/* box5 */
.hoverable .innerbox5 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox5 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox5 {
  width: 500px;
}


.hoverable:hover .innerbox5 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox5 {
  background: url('./component/assets/box5.png');
  height: 300px;
    background-size: 100%;
  display: inline-flex;
  justify-content: end;
  padding-bottom: 22px;
  cursor: pointer;
  flex-direction: column;
  width: -webkit-fill-available;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
}

/* box6 */
.hoverable .innerbox6 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox6 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox6 {
  width: 500px;
}


.hoverable:hover .innerbox6 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox6 {
  background: url('./component/assets/box6.png');
  height: 300px;
    background-size: 100%;
  display: inline-flex;
  justify-content: end;
  padding-bottom: 22px;
  flex-direction: column;
  width: -webkit-fill-available;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
  transition: opacity 0.5s ease, transform 0.5s ease;
cursor: pointer;
}

.boxfirst .box-img {
  background: transparent;
  border-radius: 13px;
  border: 1px solid #dab25d;
  width: 45%;
  height: 200px;
}
.boxfirst {
  display: flex;
  margin-top: 66px;
  gap: 30px;
}
.doc-img h2 {
  font-size: 40px;
  color: white;
  font-family: "Inria Serif", serif;
}
.boxfirst .box-img img {
  transform: translate(-26px, 19px);
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.doc-img p {
  color: white;
  font-size: 15px;
}
.doc-img {
  text-align: left;
  width: 55%;
}
.doc-img button {
  padding: 20px 14px;
  border-bottom-left-radius: 32px;
  border-top-right-radius: 32px;
  border: none;
  color: black;
}
.container.footer ul {
  list-style: none;
  padding-left: inherit;
  color: white;
  font-size: 18px;
  text-align: left;
}
.container.footer h3 {
  color: white;
  font-size: 22px;
  text-align: left;
  font-weight: bold;
}
.icons {
  display: flex;
  gap: 10px;
}
.icons svg {
  background: #ffffff24;
  padding: 8px;
  font-size: 42px;
  border-radius: 24px;
  color: #24e3c59e;
}

.container.footer {
  padding: 31px 0px;
}
section.footer .d-flex.top-footer button:hover {
  background: #24e3c59e;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
  color: rgb(206 65 211);
}
section#price-plans-section .nav-tabs .nav-link {
  color: white;
}
nav.navbar.navbar-expand-lg.navbar-light{
  background: linear-gradient(90deg, rgba(31, 138, 109, 0.1) -0.01%, rgba(114, 183, 99, 0.1) 100%);
  margin: 15px 0px;
}
.sec-three-main-div::before {
  content: url('./component/assets/Frame.png');
  position: absolute;
  filter: drop-shadow(2px 4px 6px black);
}
.sec-three-main-div {
  margin: 55px 0;
 display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sec-three.sec-three-main-div .col-sm-3{
  display: flex;
  align-items: center;
  justify-content: center;
}
.sec-three.sec-three-main-div img{
  object-fit: contain;
  width: 80%;
}
.divider button.themeButton {
  width: 20%;
  padding: 11px 17px;
  border-radius: 20px;
  margin: 0px 10px;
  background: #24E3C5;
  border: none;
  color: white;
}
.divider hr{
  width: 100%;
}
.divider {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 70px;
}
.sec-three .logo-banner-sec3{
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./component/assets/Frame.png'); */
  height: fit-content;
}
.sec-three .text-box {
  margin: 35px 0;
}
.sec-three .text-box p{
  font-size: 25px;
  text-transform: capitalize;
}
.sec-three .text-box p span{
  font-size: 30px;
  font-weight: 700;
  color: #24e3c59e;
}
.testimonial-title h2 {
  color: #24e3c59e;
}
.video-container-portfolio {
  position: relative;
    width: 100%;
    height: fit-content;
    overflow: hidden;
}
.videoTag-portfolio {
  object-fit: cover;
  width: 100%;
  height: 500px;
}
.overlay-portfolio {
  position: absolute;
    background-color: #24e3c59e;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 800px;
    background-size: contain;
    padding-top: 210px;
}
.teals-testimonials .swiper-button-prev {
  left: 0px;
  width: 70px;
  background: #24e3c5;
}
.teals-testimonials .swiper-button-prev::after {
  filter: brightness(6.5);
  font-size: 28px;
}
.teals-testimonials .swiper-button-next {
  right: 0px;
  width: 70px;
  background: #24e3c5;
}
.teals-testimonials .swiper-button-next::after {
  filter: brightness(6.5);
  font-size: 28px;
}
/* .swiper-button-prev, .swiper-button-next {
  padding: 20px;
  background: #d9d9d9;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-prev::after, .swiper-button-next::after {
  filter: hue-rotate(-439deg);
  font-size: 26px !important;
} */
.swiper.swiper-initialized.swiper-horizontal.swiper-free-mode.mainThrdSlider{
  
  padding: 24px 0px;
}
@media only screen and (max-width: 768px) {
  .footer-video-container video{
    display: none;
  }
  .footer-video-container{
    height: auto !important;
  }
  section.footer{
    position: relative !important;
  }
  .footer .footer-payment-sec{
    height: auto !important;
  }
  .footer .footer-payment-sec h5{
    font-size: 16px !important;
    padding-right: 10px !important;
    margin: auto !important;
  }
  .footer .footer-payment-sec .footer-p-div{
    width: auto !important;
  }
  .footer .footer-payment-sec .footer-p-div img{
    width: 100% !important;
  }
  .full-width-box{
    top: 40% !important;
    padding: 10px !important;
    margin: 0px 0px 6px !important;
    left: 0px !important;
    width: 100% !important;
  }
  .full-width-box .menu_container .menu_items{
    padding: 13px 19px !important;
  }
  .full-width-box .menu_container{
    width: 100% !important;
  }
  .overlay{
    padding-top: 0px;
    background-color: #24e3c5;
  }
  .overlay .second-para h1{
    font-size: 22px;
  }
  .overlay .second-para P{
    font-size: 14px;
  }
  .video-container{
    height: 310px;
  }
  .mainSlider{
    transform: scale(0.9) translate(0px, -45px);
  }
  section.Glimpse:hover .button-glips button{
    transform: translateX(0px) !important;
  }
  .my-2.my-lg-0.navbar-nav.navbar-nav-scroll{
    padding: 14px 30px;
    text-align: left;
  }
  .navbar-expand-lg .navbar-nav .nav-link.button-link{
    text-align: center;
  }
  .text-center.toggle span svg{
    font-size: 36px;
  }
  .industries__hexa-gallery{
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px 23px;
    align-items: center;
  }
  .industries__hexa{
    left: 0px !important;
  }
  .industries__hexa-text{
    color: white !important;
    font-size: 13px !important;
    line-height: 18px !important;
  }
  .industries__hexa-back {
    transform: rotateY(0deg) !important;
    filter: opacity(0.2);
}
.mainThrdSlider .swiper-slide.swiper-slide-active {
  opacity: 1 !important;
}
.overlay-portfolio{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: inherit;
}
.container.fjffjf{
  height: auto !important;
}
.bg-sec .second-para .portfolio-heading{
  font-size: 35px !important;
}
.video-container-portfolio{
  
  margin: 20px 0px;
}
.portfolio-img{
  height: 100vh;
}
.portfolio-sec-main-div .portfolio-sec .portfolio-img img{
  object-fit: fill !important;
    width: 100% ;
    height: 100%;
}
.portfolio-text-sec{
  
  padding: 29px 9px !important;
}
.portfolio-sec-main-div .portfolio-text-sec .portfolio-text-div .box-div{
  width: 100% !important;
  left: 0px !important;
  margin: 0px 0px !important;
}
section.Glimpse .text-glips h3{
  font-size: 26px !important;
}
.qoute h4{
  font-size: 26px !important;
}
section.Glimpse .button-glips{
  
  width: 125px !important;
}
.bg-sec .second-para .portfolio-p{
  font-size: 18px !important;
}
.mainThrdSlider .swiper-slide {
  opacity: 0 !important;
  transform: translateX(28px) !important;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
  .contact-form-container{
    padding: 14px;
  }
  .fixed-buttonsArrow{
    padding: 0 0px !important;
  }
  .contact-form-container{
    justify-content: flex-start !important;
  }
  /* .industries:before{
    font-size: 125px !important;
  } */
  .contact-form-container .fixed-buttons1{
    display: none !important;
  }
  .texter{
    padding-left: inherit;
  }
  .overlay .second-para button{
    width: fit-content;
  }
  .text-center.toggle{
    margin-top: 0px;
  }
  .bg-sec h4{
    font-size: 100px;
    line-height: 100px;
  }
  section.sec-two#second .row {
    justify-content: center;
}
.hoverable .innerbox1 p{
  opacity: 1;
}
.hoverable .innerbox2 p{
  opacity: 1;
}
.hoverable .innerbox3 p{
  opacity: 1;
}
.hoverable .innerbox4 p{
  opacity: 1;
}
.hoverable .innerbox5 p{
  opacity: 1;
}
.hoverable .innerbox6 p{
  opacity: 1;
}
.count-box{
  display: flex;
  gap: 0px;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.count-box p{
  text-align: center;
  font-size: 26px;
}
.count-box span{
  font-size: 82px;
}
.sec-fifth .col-lg-12.d-flex.gap-4.mt-4 {
  flex-wrap: wrap;
}
.teals-testimonials .carousel{
  height: fit-content !important;
}
.teals-testimonials-new-sec .swiper-slide.swiper-slide-active:after{
  display: none;
}
.hoverable:hover .innerbox1 {
  width: auto;
}

.hoverable:hover .innerbox2 {
  width: auto;
}

.hoverable:hover .innerbox3 {
  width: auto;
}

.hoverable:hover .innerbox4 {
  width: auto;
}

.hoverable:hover .innerbox5 {
  width: auto;
}
.hoverable:hover .innerbox6 {
  width: auto;
}
.testimonial-title p{
  font-size: 18px !important;
  padding: 0px 23px !important;
}
.second-para p {
  font-size: 18px;
}
section#price-plans-section .nav-tabs .nav-link {
  color: white;
}
.testimonial-title h2{
  font-size: 38px;
}
section.footer .d-flex.top-footer{
  flex-direction: column;
}
section.footer .d-flex.top-footer h2 {
  font-size: 40px !important;
  text-align: center !important;
}
section.footer .d-flex.top-footer button{
  padding: 9px 42px;
}
.container.footer.main .d-flex.gap-3 {
  flex-direction: column;
  margin-bottom: 28px;
  justify-content: center;
  align-items: center;
}
.container.footer.main img{
  width: 100%;
}

.container.footer ul{
  margin-bottom: 34px;
}
  .navbar {
      --bs-navbar-toggler-icon-bg: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23FFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E) !important;
      --bs-navbar-toggler-border-color: rgb(255 255 255) !important;
  }
  .bg-sec h2.heading{
    margin-top: 36px;
    font-size: 24px;
  }
  .navbar-collapse.collapse.show {
    background: #504d4dcc;
    border-radius: 20px;
    position: absolute;
    top: 96px;
    width: 94%;
    left: 3%;
}
.navbar-expand-lg .navbar-nav .nav-link{
  color: #ffffff;
}
.sec-two .text-box {
  padding: 10px 18px;
  text-align: left;
}
.product-box img{
  width: 55%;
}
.thrd-text-box h2{
  font-size: 30px;
}
section.forth-sec{
  transform: translate(0px, 0px);
}
.properties{
  margin-bottom: 20px;
}
.sec-fifth{

  padding: 46px 0px 46px;
}
.boxfirst .box-img img{

  transform: translate(-8px, -26px);
}
.boxfirst{
  margin-top: 41px;
}
.doc-img h2{
  font-size: 22px;
}
.doc-img p{
  font-size: 13px;
}
.doc-img button{
  padding: 10px 17px;
}
.container.footer {
  padding: 31px 20px;
}
.sec-two .text-box h2{
  font-size: 34px;
}
.sec-two .text-box p{
  font-size: 14px;
}
.product-box{
  height: 265px;
}
.third-sec .img-box .shadow-img{
  height: 200px;
    width: 200px;
}
.shadow-box{
  margin-top: -200px;
}
.headingsForth h2{
  font-size: 45px;
}
.headingsForth p{
  font-size: 13px;
}
section.footer{
  position: inherit;
  background-color: #24e3c5 !important;
}
}
.footer-video-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  background-clip: './component/assets/footer.mp4';
  position: relative;
    width: 100%;
    height: 450px;
}
section.footer{
  background-color: #24e3c59e;
  width: 100%;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
}
/* ---- tsparticles container ---- */
#tsparticles {
  position: absolute;
  width: 100%;
  background-color: #353535;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  left: 0px;
  height: 450px;
  border: 0px;
  overflow-x: auto;
}

/* testimonial */
section.footer .d-flex.top-footer {
  justify-content: space-between;
  color: white;
  align-items: center;
}

section.footer .d-flex.top-footer h2 {
  font-size: 80px;
  text-align: left;
}

section.footer .d-flex.top-footer button {text-align: center;justify-content: center;padding: 13px 40px;border-color: #24e3c59e;border-width: 7px;border-radius: 101px;background: transparent;color: white;font-size: 23px;font-weight: 700;}
.container.footer.main img {
  object-fit: contain;
  width: 55%;
}
.hoverable-new.hoverable-sec2.aos-init.aos-animate[data-aos^=fade][data-aos^=fade] {
  transition-property: unset;
}
.container.footer.main li {
  margin-top: 12px;
  
  width: fit-content;
  cursor: pointer;
}
.container.footer.main a{
  color: white;
  text-decoration: none;
}
  .gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
}
.gallery-item:hover {
    transform: scale(0.9);
    transition: .9s;
}

.gallery-item {
    flex-basis: 32.7%;
    margin-bottom: 6px;
    opacity: 1 !important;
    cursor: pointer;
}
 .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.fixed-buttons {
  top: 50%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: fixed;
  right: -78px;
  z-index: 999;
}

.fixed-buttons:hover {
  right: -69px;
  transform: scale(0.9);
  transition: .4s;
}

.fixed-buttons button {
  background: #00000079;
  border: 2px solid gray;
  text-align: center;
  justify-content: center;
  padding: 9px 32px;
  border-color: #24e3c59e;
  font-size: 20px;
  border-width: 5px;
  border-top-left-radius: 28px;
  color: white;
  border-top-right-radius: 28px;
  font-weight: 600;
  transform: scale(0.9) rotate(-90deg);
  transition: .9s;
}
.contact-form-container .fixed-buttons1 {
  display: flex;
  flex-direction: column;
  gap: 25px;
  z-index: 999;
  transform: rotate(-90deg) translate(0px, -263px);
    position: absolute;
}

.contact-form-container .fixed-buttons1:hover {
  /* right: -69px; */
  transform: scale(0.9) rotate(-90%);
  transition: .4s;
}

.contact-form-container .fixed-buttons1 button {
  transform: rotate(-90deg);
    background: transparent;
    border: 2px solid gray;
    text-align: center;
    justify-content: center;
    padding: 9px 32px;
    border-color: #24e3c59e;
    font-size: 20px;
    border-width: 5px 5px 0px;
    border-top-left-radius: 28px;
    color: white;
    border-top-right-radius: 28px;
    font-weight: 600;
    transform: scale(0.9);
    transition: .9s;
}
.fixed-buttonsArrow {
  display: flex;
  flex-direction: column;
  gap: 25px;
  z-index: 999;
  transform: rotate(0deg) translate(0px, 0px);
  padding: 0px 16px;
  position: relative;
}
.fixed-buttonsArrow svg {
  color: #24e3c59e;
  font-size: -webkit-xxx-large;
  cursor: pointer;
}
.fixed-buttonsArrow:hover {
  /* right: -69px; */
  transform: scale(0.9) rotate(-90%);
  transition: .4s;
}


.contact-form-container {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  transition: right 0.4s ease;
  z-index: 99999;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.contact-form-container.open {
  right: 0; /* Slide into view */
  width: 100%;
}

.contact-form-container  .contact-form {
  padding: 20px;
  color: white;
  border-radius: 17px;
  border: 6px solid #24e3c59e;
  background: #00000045;
  backdrop-filter: blur(2px);
  width: 400px;
}

.contact-form h2 {
  color: #fff;
  margin-bottom: 20px;
}

.contact-form input, 
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: transparent;
  border: 2px solid gray;
  display: flex;
  align-items: center;
}

.contact-form button {
  width: 100%;
  padding: 12px;
  background-color: #24e3c59e;
  border: none;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #8100b4;
}

.arrowup-static, .phone-static {
  background: #fff;
  border-radius: 53px;
  color: #ff6500;
  font-size: 31px;
  height: 40px;
  margin-left: 2px;
  padding: 12px;
  width: 40px;
}
.icons .icon-img {
  padding: 6px;
  /* font-size: 42px; */
  width: fit-content !important;
  height: 40px;
}
.footer .footer-payment-sec {
  width: 100%;
  background-color: #4f4f4f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 9px;
  height: 55px;
}
.footer .footer-payment-sec h5 {
  color: #72b763;
  font-size: 20px;
}
.footer .footer-payment-sec .footer-p-div {
  display: flex;
  width: 50%;
  justify-content: end;
  gap: 0 10px;
}
/* .footer .footer-payment-sec .footer-p-div div{
  width: 11%;
} */
.footer .footer-payment-sec .footer-p-div img{
  object-fit: contain;
  width: auto;
  height: auto;
}
.footer .footer-bottom-line{
  border-bottom: 1px solid #4f4f4f;
  margin: 25px 0;
}
.footer .footer-banner {
  text-align: left;
  color: white;
}
.footer .footer-banner span{
  color: black;
}
.portfolio-sec-main-div .portfolio-text-sec {
  height: auto;
}
.sec-three.sec-three-main-div{
  display: none;
}
.portfolio-sec-main-div {
  position: relative;
  padding: 100px 0;
}

.portfolio-img {
  position: absolute;
  transition: transform 0.3s ease-in-out, bottom 0.3s ease-in-out, top 0.3s ease-in-out;
  left: 0px;
  top: 0px;
  border: 0px;
  max-width: 757px;
}

.portfolio-img.move-to-top {
  bottom: 0px;
  top: auto;
}


.portfolio-img.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 757px;
}

/* left */
.portfolio-text-sec {
  padding: 40px 40px;
  /* height: 200vh; */
  background: #ffff;
  background: #fff;
  color: black;
  text-align: left;
  border-top-right-radius: 147px;
}
.portfolio-text-sec h6 {
  color: #4F4F4F;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Inter';
}
.portfolio-text-sec h4 {
  font-size: 40px;
  font-weight: 600;
}


/* right */
.portfolio-text-sec-right {
  padding: 40px 40px;
  background: #44e4c4;
  color: white;
  text-align: right;
  border-top-left-radius: 147px;
  /* height: 280px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  transition: opacity 0.5s ease, transform 0.5s ease;

}
.animated-text {
  opacity: 0;                 /* Hide paragraph by default */
  max-height: 0;              /* Collapse the height */
  overflow: hidden;           /* Hide overflow content */
  transition: all 0.5s ease;  /* Animation for visibility */
}
.portfolio-text-sec-right:hover .animated-text {
  opacity: 1;                 /* Make paragraph visible */
  max-height: 500px;          /* Expand the height */
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.portfolio-text-sec-right h6 {
  color: #4F4F4F;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Inter';
}
.portfolio-text-sec-right h4 {
  font-size: 40px;
  font-weight: 600;
  width: 80%;
}
.portfolio-text-sec-right p{
  /* display: none; */
  transition: 0.5s;
}
/* .portfolio-text-sec-right:hover h6{
  display: none;
}
.portfolio-text-sec-right:hover h4{
  display: none;
} */
.portfolio-text-sec-right:hover p{
  display: block;
  font-size: 20px;
}

/* Glimpse Section */
section.Glimpse {
  background: url(./component/assets/glipseBanner.png);
  background-repeat: round;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

section.Glimpse .text-glips h3 {
  font-size: 47px;
  text-align: left;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

section.Glimpse .button-glips {
  display: flex;
  justify-content: flex-start; /* Default start position */
  width: 321px;
  background: #88B4AD;
  padding: 0px;
  border-radius: 39px;
  border: 2px solid #9d9d9d;
  position: relative;
  overflow: hidden; /* Hide any overflow during the animation */
}

section.Glimpse .button-glips button {
  padding: 10px 21px;
  border-radius: 100px;
  border: 3px solid #62a79c;
  background: #24E3C5;
  color: white;
  font-size: 19px;
  cursor: pointer;
  transition: transform 0.8s ease; /* Smooth transition for movement */
  position: relative;
  z-index: 1; /* Ensure button stays above the background */
}

/* Hover effect to move the button to the right */
section.Glimpse:hover .button-glips button {
  transform: translateX(200px); /* Move button to the right (adjust distance as needed) */
}

section.Glimpse .button-glips button:hover {
  transform: translateX(196px); /* Slightly enlarge while moving */
}

.firstTextSec{
  
  width: 480px;
}
.approach h3,
.blog-inner-masthead p,
.card__content-para p,
.ceo p,
.choose-us__para,
.contact-us__info-link address,
.contact-us__info-link p,
.copy p,
.cs-text-content__note p,
.csr-gallery__quote,
.faq__contents h3,
.field_description_below p,
.header__menu-cont p,
.hero-investment .description,
.hero-investment .hero-investment__content-second-title,
.industries__hexa-gallery .industries__hexa:last-child .industries__hexa-text,
.journey .journey__top-content-para,
.journey__sparrow-main > .container > .row p.para2,
.lets-know .lets-know__left-cont-para,
.next-client p,
.portfolio .portfolio__inner-cont-sub-title,
.process__btn p,
.process__inner h3,
.recent-blog h3,
.technology__content p,
.testimonial .author,
.testimonial .text,
.thank-you__cont p,
h6 {
  color: #030510;
  font-family: Agrandir, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: -0.48px;
  line-height: 2.5rem;
}
@media (min-width: 1025px) {
  .approach h3,
  .blog-inner-masthead p,
  .card__content-para p,
  .ceo p,
  .choose-us__para,
  .contact-us__info-link address,
  .contact-us__info-link p,
  .copy p,
  .cs-text-content__note p,
  .csr-gallery__quote,
  .faq__contents h3,
  .field_description_below p,
  .header__menu-cont p,
  .hero-investment .description,
  .hero-investment .hero-investment__content-second-title,
  .industries__hexa-gallery .industries__hexa:last-child .industries__hexa-text,
  .journey .journey__top-content-para,
  .journey__sparrow-main > .container > .row p.para2,
  .lets-know .lets-know__left-cont-para,
  .next-client p,
  .portfolio .portfolio__inner-cont-sub-title,
  .process__btn p,
  .process__inner h3,
  .recent-blog h3,
  .technology__content p,
  .testimonial .author,
  .testimonial .text,
  .thank-you__cont p,
  h6 {
    font-size: 2.2rem;
    line-height: 3.6rem;
  }
}
@media (min-width: 1699px) {
  .approach h3,
  .blog-inner-masthead p,
  .card__content-para p,
  .ceo p,
  .choose-us__para,
  .contact-us__info-link address,
  .contact-us__info-link p,
  .copy p,
  .cs-text-content__note p,
  .csr-gallery__quote,
  .faq__contents h3,
  .field_description_below p,
  .header__menu-cont p,
  .hero-investment .description,
  .hero-investment .hero-investment__content-second-title,
  .industries__hexa-gallery .industries__hexa:last-child .industries__hexa-text,
  .journey .journey__top-content-para,
  .journey__sparrow-main > .container > .row p.para2,
  .lets-know .lets-know__left-cont-para,
  .next-client p,
  .portfolio .portfolio__inner-cont-sub-title,
  .process__btn p,
  .process__inner h3,
  .recent-blog h3,
  .technology__content p,
  .testimonial .author,
  .testimonial .text,
  .thank-you__cont p,
  h6 {
    font-size: 2.6rem;
    line-height: 3.8rem;
  }
}
.dark-bg .approach h3,
.dark-bg .blog-inner-masthead p,
.dark-bg .card__content-para p,
.dark-bg .ceo p,
.dark-bg .choose-us__para,
.dark-bg .contact-us__info-link address,
.dark-bg .contact-us__info-link p,
.dark-bg .copy p,
.dark-bg .cs-text-content__note p,
.dark-bg .csr-gallery__quote,
.dark-bg .faq__contents h3,
.dark-bg .field_description_below p,
.dark-bg .header__menu-cont p,
.dark-bg .hero-investment .description,
.dark-bg .hero-investment .hero-investment__content-second-title,
.dark-bg
  .industries__hexa-gallery
  .industries__hexa:last-child
  .industries__hexa-text,
.dark-bg .journey .journey__top-content-para,
.dark-bg .journey__sparrow-main > .container > .row p.para2,
.dark-bg .lets-know .lets-know__left-cont-para,
.dark-bg .next-client p,
.dark-bg .portfolio .portfolio__inner-cont-sub-title,
.dark-bg .process__btn p,
.dark-bg .process__inner h3,
.dark-bg .recent-blog h3,
.dark-bg .technology__content p,
.dark-bg .testimonial .author,
.dark-bg .testimonial .text,
.dark-bg .thank-you__cont p,
.dark-bg h6 {
  color: #fff;
}
.approach h3 strong,
.blog-inner-masthead p strong,
.card__content-para p strong,
.ceo p strong,
.choose-us__para strong,
.contact-us__info-link address strong,
.contact-us__info-link p strong,
.copy p strong,
.cs-text-content__note p strong,
.csr-gallery__quote strong,
.faq__contents h3 strong,
.field_description_below p strong,
.header__menu-cont p strong,
.hero-investment .description strong,
.hero-investment .hero-investment__content-second-title strong,
.industries__hexa-gallery
  .industries__hexa:last-child
  .industries__hexa-text
  strong,
.journey .journey__top-content-para strong,
.journey__sparrow-main > .container > .row p.para2 strong,
.lets-know .lets-know__left-cont-para strong,
.next-client p strong,
.portfolio .portfolio__inner-cont-sub-title strong,
.process__btn p strong,
.process__inner h3 strong,
.recent-blog h3 strong,
.technology__content p strong,
.testimonial .author strong,
.testimonial .text strong,
.thank-you__cont p strong,
h6 strong {
  font-family: Agrandir, sans-serif;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: normal;
}
@media (min-width: 1699px) {
  .approach h3 strong,
  .blog-inner-masthead p strong,
  .card__content-para p strong,
  .ceo p strong,
  .choose-us__para strong,
  .contact-us__info-link address strong,
  .contact-us__info-link p strong,
  .copy p strong,
  .cs-text-content__note p strong,
  .csr-gallery__quote strong,
  .faq__contents h3 strong,
  .field_description_below p strong,
  .header__menu-cont p strong,
  .hero-investment .description strong,
  .hero-investment .hero-investment__content-second-title strong,
  .industries__hexa-gallery
    .industries__hexa:last-child
    .industries__hexa-text
    strong,
  .journey .journey__top-content-para strong,
  .journey__sparrow-main > .container > .row p.para2 strong,
  .lets-know .lets-know__left-cont-para strong,
  .next-client p strong,
  .portfolio .portfolio__inner-cont-sub-title strong,
  .process__btn p strong,
  .process__inner h3 strong,
  .recent-blog h3 strong,
  .technology__content p strong,
  .testimonial .author strong,
  .testimonial .text strong,
  .thank-you__cont p strong,
  h6 strong {
    letter-spacing: 0;
  }
}
.industries {
  background:#232323;
  overflow: hidden;
  padding: 10rem 0 7.5rem;
}
/* .industries:before {
  content: 'APEX';
  position: absolute;
  color: #ffffff24;
  font-size: 286px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  height: 100vh;
} */
@media (min-width: 768px) {
  .industries {
    padding: 15rem 0;
  }
}
@media (min-width: 1699px) {
  .industries {
    padding: 20rem 0;
  }
}
.industries__content {
  text-align: left;
  margin: 0 auto 4rem;
}
@media (min-width: 768px) {
  .industries__content {
    margin: auto;
  }
}
@media (min-width: 1699px) {
  .industries__content {
    margin: 0 auto 10rem;
  }
}
.industries__text,
.industries__title {
  overflow: hidden;
}
.industries__text {
  font-size: 22px;
  color: #b5b2be !important;
}
.industries__title {
  margin-bottom: 1rem !important;
  font-size: 41px;
}
.industries__title span {
  color: #24E3C5;
}
@media (min-width: 1025px) {
  .industries__title {
    margin-bottom: 5rem;
  }
}
.industries__hexa {
  background: #03051082;
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  display: block;
  height: 130px;
  left: -25px;
  perspective: 1000px;
  position: relative;
  text-decoration: none !important;
  width: 110px;
}
@media (min-width: 1025px) {
  .industries__hexa {
    display: flex;
    height: calc(150% - 20px);
    left: auto;
    transform: scale(1);
    width: 100%;
  }
  .industries__hexa.start {
    overflow: hidden;
  }
}
@media (min-width: 1699px) {
  .industries__hexa {
    height: calc(145% - 20px);
  }
}
.industries__hexa.start {
  transform: scale(1);
}
.center .industries__hexa {
  background: #030510 !important;
  transition-delay: 0s;
}
.industries__hexa-inner {
  height: 100%;
  position: relative;
  text-align: center;
  transform-style: preserve-3d;
  transition: all 0.5s linear;
  width: 100%;
}
@media (min-width: 1025px) {
  .industries__hexa:hover .industries__hexa-inner {
    transform: rotateY(180deg);
  }
}
.center .industries__hexa-inner {
  transform: rotateY(180deg);
}
.industries__hexa-back,
.industries__hexa-front {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform-style: preserve-3d;
  width: 100%;
}
.industries__hexa-front {
  /* background: #030510; */
  overflow: hidden;
  position: relative;
}
.industries__hexa-front,
.industries__hexa-front:after,
.industries__hexa-front:before {
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}
.industries__hexa-front:after,
.industries__hexa-front:before {
  background: rgb(0 226 197 / 0%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 1025px) {
  .industries__hexa-front:after,
  .industries__hexa-front:before {
    background: #030510;
  }
}
.industries__hexa-front:after {
  background: #03051000;
  content: "";
  height: calc(100% - 2px);
  left: 1px;
  position: absolute;
  top: 1px;
  width: calc(100% - 2px);
}
.industries__hexa-back {
  transform: rotateY(180deg);
}
.center .industries__hexa {
  background: #030510;
  height: 180px;
  width: 160px;
}
.center .industries__hexa .industries__hexa-text,
.industries__hexa:hover .industries__hexa-text {
  background: rgba(3, 5, 16, 0);
}
.industries__hexa-content {
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
  color: #404040 !important;
  display: none;
  opacity: 0;
}
@media (min-width: 1025px) {
  .industries__hexa-content {
    bottom: 0;
    max-width: 300px;
    position: absolute;
    right: 15px;
  }
}
@media (min-width: 1370px) {
  .industries__hexa-content {
    max-width: 450px;
  }
}
@media (min-width: 1699px) {
  .industries__hexa-content {
    max-width: 490px;
  }
}
.industries__hexa-content.active {
  animation-name: fadeIn;
  display: block;
}
.industries__hexa-color,
.industries__hexa-img,
.industries__hexa-text,
.industries__hexa-text-cont {
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.industries__hexa-text {
  align-items: center;
  display: flex;
  font-weight: 400 !important;
  justify-content: center;
  text-align: center;
}
@media (max-width: 1024px) {
  .industries__hexa-text {
    font-size: 16px;
    line-height: 26px;
  }
}
.industries__hexa-text-cont {
  background: none !important;
  z-index: 10;
}
.industries__hexa-back .industries__hexa-text {
  transform: translateZ(60px) scale(0.94);
  display: none;
}
.industries__hexa-gallery {
  margin-bottom: 2rem;
  margin-left: 0;
  width: 160px;
}
@media (min-width: 1025px) {
  .industries__hexa-gallery {
    grid-gap: 15px;
    display: grid;
    grid-auto-rows: 130px;
    grid-template-columns: repeat(10, 1fr);
    margin-bottom: 0;
    /* margin-top: 50px; */
    max-width: calc(100% - 70px);
    padding-bottom: 61px;
    width: auto;
  }
}
@media (min-width: 1370px) {
  .industries__hexa-gallery {
    grid-auto-rows: 140px;
    max-width: calc(100% - 200px);
  }
}
@media (min-width: 1699px) {
  .industries__hexa-gallery {
    grid-auto-rows: 200px;
  }
}
.industries__hexa:hover .industries__hexa-back .industries__hexa-text{
  display: flex;
  cursor: pointer;
}
.full-width-box {
  background: rgb(255 255 255 / 0%);
  top: 54%;
  /* height: 100%; */
  overflow-x: hidden;
  position: absolute;
  z-index: 999999;
  left: 0px;
  padding: 21px;
  margin: 0px 10px 20px;
  width: 100%;
  align-self: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
.full-width-box p {
  margin: 0;
  padding: 5px 0;
  color: #333; /* Set text color for the service categories */
}
.full-width-box .menu_container{
  -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    width: 68%;
    border: 2px solid #24E3C5;
    border-radius: 19px;
    background: #0000004f;
    color: white;
}
.full-width-box .menu_container a::before{
  display: none;
}
.full-width-box .menu_container a{
  color: white;
  position: relative;
  text-decoration: none;
}
.full-width-box .menu_container .menu_items .develop{
  animation: updown 2s ease-in-out infinite !important;
}
.full-width-box .menu_container .menu_items {
  backdrop-filter: opacity(0.5);
  position: relative;
  padding: 18px 10px;
}
a.button-link.nav-link {
  box-shadow: 0 0 6px 0px inset rgba(0, 0, 0, 0.4);
  background: #24E3C5;
  color: white !important;
  padding: 8px 23px;
  border-radius: 11px;
}
/* .full-width-box .menu_container .menu_items .resp-submenu{
  margin-top: 20px;
} */
.col-lg-6.col-md-6.col-sm-12.col-12.resp-submenu{
  text-align: left;
}
.container.mainSlider .slick-initialized .slick-slide {
  padding: 0px 5px;
}
.full-width-box .menu_container .menu_items .resp-submenu {

  text-align: left;
  margin-top: 18px;
  margin-bottom: 18px;
  width: -webkit-fill-available;
}
.full-width-box .menu_container .menu_items .resp-submenu span.menu_type.under_line {
  text-transform: uppercase;
}
@media (min-width: 1025px) {
  .industries__hexa:first-child {
    grid-column: 2 / span 2;
    grid-row-start: 1;
    position: relative;
  }
  .industries__hexa:first-child .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries__hexa:nth-child(2) {
    grid-column: 4 / span 2;
    grid-row-start: 1;
    position: relative;
  }
  .industries__hexa:nth-child(2) .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries__hexa:nth-child(3) {
    grid-column: 8 / span 2;
    grid-row-start: 1;
    position: relative;
  }
  .industries__hexa:nth-child(3) .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries__hexa:nth-child(4) {
    grid-column: 1 / span 2;
    grid-row-start: 2;
  }
  .industries__hexa:nth-child(4) .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries__hexa:nth-child(5) {
    grid-column: 3 / span 2;
    grid-row-start: 2;
    position: relative;
  }
  .industries__hexa:nth-child(5) .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries__hexa:nth-child(6) {
    grid-column: 5 / span 2;
    grid-row-start: 2;
    position: relative;
  }
  .industries__hexa:nth-child(6) .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries__hexa:nth-child(7) {
    grid-column: 7 / span 2;
    grid-row-start: 2;
    position: relative;
  }
  .industries__hexa:nth-child(7) .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries__hexa:nth-child(8) {
    grid-column: 9 / span 2;
    grid-row-start: 2;
    position: relative;
  }
  .industries__hexa:nth-child(8) .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries__hexa:nth-child(9) {
    grid-column: 2 / span 2;
    grid-row-start: 3;
    position: relative;
  }
  .industries__hexa:nth-child(9) .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries__hexa:nth-child(10) {
    grid-column: 6 / span 2;
    grid-row-start: 3;
    position: relative;
  }
  .industries__hexa:nth-child(10) .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries__hexa:nth-child(11) {
    background: #03051082;
    grid-column: 10 / span 2;
    grid-row-start: 1;
    left: 1px;
    position: relative;
    width: calc(200% - 15px);
  }
  .industries__hexa:nth-child(11) .industries__hexa-front {
    position: relative;
  }
  .industries__hexa:nth-child(11).start {
    transition: all 0ms linear;
  }
  .industries__hexa:nth-child(11).start,
  .industries__hexa:nth-child(11).start * {
    transform: scale(1) !important;
  }
  .industries__hexa:nth-child(11) .industries__hexa-text {
    color: #eee !important;
    position: relative;
    z-index: 111;
    z-index: 15;
  }
}
@media (max-width: 1024px) {
  .industries__hexa,
  .industries__hexa * {
    transition: none !important;
    transition-delay: 0 !important;
  }
}
.industries .owl-carousel .owl-stage-outer {
  overflow: visible;
}
.industries .owl-carousel.owl-loaded {
  position: static;
}
@media (max-width: 1024px) {
  .industries .owl-carousel.owl-loaded {
    transform: none !important;
    will-change: auto !important;
  }
}
.industries .owl-carousel .owl-stage {
  align-items: center;
  display: flex;
  min-height: 180px;
}
.industries .container {
  position: relative;
}
.industries .container .col-12 {
  position: static;
}
.industries .owl-dots {
  bottom: -30px;
  display: flex;
  position: absolute;
}
@media (min-width: 1025px) {
  .industries .owl-dots {
    bottom: 0;
  }
}
.industries .owl-dots .owl-dot {
  background: #eee;
  height: 2px;
  margin-right: 10px;
  width: 20px;
}
@media (min-width: 1025px) {
  .industries .owl-dots .owl-dot {
    background: hsla(0, 0%, 100%, 0.2);
  }
}
.industries .owl-dots .owl-dot.active {
  background: #00e2c5;
  height: 2px;
  margin-right: 10px;
  width: 50px;
}
.industries .owl-dots .owl-dot:before {
  display: none;
}
.industries .headline,
.industries .para,
.industries .para2,
.industries h1,
.industries h2,
.industries h3,
.industries h4,
.industries h5,
.industries h6,
.industries p {
  color: #fff;
}
.industries.light-bg {
  background: #fff;
}
.industries.light-bg .headline,
.industries.light-bg .para,
.industries.light-bg .para2,
.industries.light-bg h1,
.industries.light-bg h2,
.industries.light-bg h3,
.industries.light-bg h4,
.industries.light-bg h5,
.industries.light-bg h6,
.industries.light-bg p {
  color: #030510;
}
.industries.light-bg .industries__hexa-text {
  color: #fff !important;
}
@media (min-width: 1024px) {
  .industries.light-bg .industries__hexa-text {
    color: #030510 !important;
  }
}
.industries.light-bg .industries__hexa {
  background: #fff;
}
.industries.light-bg .industries__hexa:hover .industries__hexa-text {
  color: #fff !important;
}
@media (min-width: 1025px) {
  .industries.light-bg
    .industries__hexa:first-child
    .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries.light-bg
    .industries__hexa:nth-child(2)
    .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries.light-bg
    .industries__hexa:nth-child(3)
    .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries.light-bg
    .industries__hexa:nth-child(4)
    .industries__hexa-front:before {
    background: rgba(0, 226, 197, 0.9);
  }
  .industries.light-bg
    .industries__hexa:nth-child(5)
    .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries.light-bg
    .industries__hexa:nth-child(6)
    .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries.light-bg
    .industries__hexa:nth-child(7)
    .industries__hexa-front:before {
    background: rgba(0, 226, 197, 0.5);
  }
  .industries.light-bg
    .industries__hexa:nth-child(8)
    .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries.light-bg
    .industries__hexa:nth-child(9)
    .industries__hexa-front:before {
    background: rgb(0 226 197 / 0%);
  }
  .industries.light-bg
    .industries__hexa:nth-child(10)
    .industries__hexa-front:before {
    background: rgba(0, 226, 197, 0.5);
  }
}
.industries.light-bg .industries__hexa-front,
.industries.light-bg .industries__hexa-front:after,
.industries.light-bg .industries__hexa:nth-child(11) {
  background: #fff;
}
.industries.light-bg .industries__hexa:nth-child(11) .industries__hexa-text {
  color: #b5b2be !important;
}
.industries-slider {
  background-color: #030510;
  margin: -2px auto;
  overflow: hidden;
  padding: 4rem 0;
  position: relative;
}
@media (min-width: 768px) {
  .industries-slider {
    padding: 6rem 0;
  }
}
@media (min-width: 1025px) {
  .industries-slider {
    overflow: visible;
    padding: 12rem 0;
  }
}
.industries-slider .owl-stage,
.industries-slider .owl-stage-outer {
  display: flex;
  overflow: visible;
}
.industries-slider .slider-row {
  flex-wrap: nowrap !important;
  margin-left: 0;
  margin-right: 0;
  overflow: visible;
}
.industries-slider__content {
  margin-bottom: 2rem;
  overflow: hidden;
}
@media (min-width: 768px) {
  .industries-slider__content {
    margin-bottom: 3rem;
  }
}
@media (min-width: 1025px) {
  .industries-slider__content {
    margin-bottom: 6rem;
  }
}
@media (min-width: 1699px) {
  .industries-slider__content {
    margin-bottom: 9rem;
  }
}
.industries-slider__content p {
  color: #b5b2be !important;
}
.industries-slider__slider-wrp {
  height: 370px;
  position: relative;
  width: 316px;
}
@media (min-width: 1025px) {
  .industries-slider__slider-wrp {
    height: 400px;
    width: 100%;
  }
}
@media (min-width: 1370px) {
  .industries-slider__slider-wrp {
    height: 500px;
  }
}
@media (min-width: 1699px) {
  .industries-slider__slider-wrp {
    height: 556px;
  }
}
.industries-slider__slider .col-12 {
  padding: 0;
}
.industries-slider__card {
  float: left;
  height: 370px;
  left: 0;
  overflow: hidden;
  perspective: 1000px;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 1025px) {
  .industries-slider__card {
    height: 400px;
  }
}
@media (min-width: 1370px) {
  .industries-slider__card {
    height: 500px;
  }
}
@media (min-width: 1699px) {
  .industries-slider__card {
    height: 556px;
  }
}
.industries-slider__card-content {
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
  width: 100%;
}
.industries-slider__card-img {
  display: block;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  z-index: -1;
}
.industries-slider__card-front {
  overflow: visible;
  text-align: right;
}
.industries-slider__card-front:before {
  background: linear-gradient(180deg, transparent, #000);
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.industries-slider__card-front-title {
  margin-right: 30px;
  margin-top: -60px;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .industries-slider__card-front-title {
    margin-right: 30px;
    margin-top: -60px;
  }
}
@media (min-width: 1025px) {
  .industries-slider__card-front-title {
    margin-right: 30px;
    margin-top: -90px;
  }
}
@media (min-width: 1370px) {
  .industries-slider__card-front-title {
    margin-right: 70px;
    margin-top: -100px;
  }
}
@media (min-width: 1699px) {
  .industries-slider__card-front-title {
    margin-right: 70px;
    margin-top: -130px;
  }
}
.industries-slider__card-back,
.industries-slider__card-front {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.industries-slider__card-back {
  background: #00e2c5;
  border-radius: 0 200px 0 0;
  position: relative;
  text-align: center;
  transform: rotateY(180deg);
}
.industries-slider__card-back-text,
.industries-slider__card-back-title {
  color: #000 !important;
}
.industries-slider__card-back-inner {
  padding: 0 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1025px) {
  .industries-slider__card-back-inner {
    padding: 0 3rem;
  }
}
@media (min-width: 1370px) {
  .industries-slider__card-back-inner {
    padding: 0 5rem;
  }
}
.industries-slider__card:hover .industries-slider__card-content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}
.industries__hexa.industries__hexa-more p.industries__hexa-text {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-decoration: none !important;
}
@media (min-width: 1025px) {
  .qoute {
      padding: 15rem 0 10rem;
  }
}

.qoute {
  background: #030510;
  margin: -1px auto;
  overflow: hidden;
  padding: 7.5rem 0;
  text-align: center;
}
@media (min-width: 1025px) {
  .qoute h3 {
      margin-bottom: 7rem;
      margin-top: 50px;
  }
}

.qoute h3 {
  color: #fff;
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .qoute__content {
      margin-bottom: 3rem;
  }
}

.qoute__content {
  margin-bottom: 1rem;
}
.qoute__heading span {
  font-family: Agrandir, sans-serif !important;
}
@media (min-width: 768px) {
  .qoute__content-first {
      padding: 30px 0;
      transform: rotate(2deg);
      z-index: 1;
  }
}

.qoute__content-first {
  padding: 20px 0;
  position: relative;
  transform: rotate(4deg);
}
@media (min-width: 768px) {
  .qoute__content>div:before {
      width: calc(100% + 12px);
  }
}

.qoute__content>div:before {
  content: "";
  height: 100%;
  left: -10px;
  position: absolute;
  top: 0;
  width: calc(100% + 14px);
  z-index: 0;
}
.qoute__content-first:before {
  background: #00e2c5;
}
.qoute__content-first h4 {
  color: #030510 !important;
}

@media (min-width: 1025px) {
  .qoute h4 {
      font-size: 4.2rem;
      line-height: 5.5rem;
  }
}
.qoute h4 {
  color: #fff;
  font-family: Agrandir, sans-serif;
  font-size: 2.6rem;
  font-weight: 400;
  letter-spacing: -.02em;
  line-height: 3.2rem;
  margin-bottom: 0;
  position: relative;
  white-space: nowrap;
}
@media (min-width: 1025px) {
  .qoute__content-second {
    rotate: -1deg;
    margin: 17px 0 -7px;
    padding: 30px 0;
    z-index: 1;
  }
}

.qoute__content-second {
  padding: 20px 0;
  position: relative;
}
.qoute__content-second:before {
  background: #fff;
}
.qoute__content-second h4 {
  color: #030510;
  position: relative;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .qoute__content-third {
      padding: 30px 0;
      transform: rotate(2deg);
      z-index: 1;
  }
}

.qoute__content-third {
  padding: 20px 0;
  position: relative;
  transform: rotate(1deg);
}
.qoute__content-third:before {
  background: #005bff;
}
.qoute__heading, 
.qoute__content-first, 
.qoute__content-second, 
.qoute__content-third {
  transition: transform 0.1s ease-out;
  will-change: transform;
}
.digitalBanner {
  padding: 25px 0px;
  text-align: left;
}
.ppcBanner {
  padding: 25px 0px;
  text-align: left;
}
.digitalBanner h2 {
  color: #000;
  font-size: 50px;
}
.ppcBanner h2 {
  color: #000;
  font-size: 50px;
}
.ppcBanner-secpara {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ppcBanner-secpara h3 {
  color: #000;
  font-size: 40px;
}
.digitalBanner h2 span{
  color: #24E3C5;
}
.ppcBanner h2 span{
  color: #24E3C5;
}
.ppcBanner-secpara h3 span{
  color: #24E3C5;
}

.digitalBanner p {
  margin-top: 35px;
  word-spacing: 5px;
  line-height: 29px;
  font-size: 20px;
  width: fit-content;
}
section#price-plans-section .price-inner-plan:hover p{
color: white;
}
section#price-plans-section .price-inner-plan p{
color: #24E3C5;
}
.ppcBanner p{
  margin-top: 35px;
  word-spacing: 5px;
  line-height: 29px;
  font-size: 20px;
  width: fit-content;
}
/* Glimpse Section */
/* section.Glimpse {
  background: url(./component/assets/glipseBanner.png);
  background-repeat: round;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.digitalBanner .text-glips h3 {
  font-size: 47px;
  text-align: left;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}
.digitalBanner .button-glips:hover button {
  transform: translateX(197px);
}

.digitalBanner .button-glips {
  display: flex;
  justify-content: flex-start; /* Default start position */
  width: 320px;
  background: #88B4AD;
  padding: 0px;
  border-radius: 39px;
  border: 2px solid #9d9d9d;
  margin: 0px 12px 0px 0px;
  position: relative;
  overflow: hidden; /* Hide any overflow during the animation */
}
.ppcBanner .button-glips {
  display: flex;
  justify-content: flex-start; /* Default start position */
  width: 30%;
  background: #88B4AD;
  padding: 0px;
  border-radius: 39px;
  border: 2px solid #9d9d9d;
  margin: 0px 12px 0px 0px;
  position: relative;
  overflow: hidden; /* Hide any overflow during the animation */
}
.digitalBanner .button-glips button {
  padding: 10px 21px;
  border-radius: 100px;
  border: 3px solid #62a79c;
  background: #24E3C5;
  color: white;
  font-size: 19px;
  cursor: pointer;
  transition: transform 0.8s ease; /* Smooth transition for movement */
  position: relative;
  z-index: 1; /* Ensure button stays above the background */
}
.ppcBanner .button-glips button {
  padding: 10px 21px;
  border-radius: 100px;
  border: 3px solid #62a79c;
  background: #24E3C5;
  color: white;
  font-size: 19px;
  cursor: pointer;
  transition: transform 0.8s ease; /* Smooth transition for movement */
  position: relative;
  z-index: 1; /* Ensure button stays above the background */
}

  section.designBannerSec .secdesign{
    background: url(./component/assets/rectangle.png);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 38px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    /* transform: translate(0px, 54px); */
  }
  section.DigitalDesignBannerSec  .secdesign{
    background: url(./component/assets/rectangle.png);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 38px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    transform: translate(0px, 54px);
  }
  section.appBannerSec .secdesign{
    background: url(./component/assets/rectangle.png);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 38px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    transform: translate(0px, 54px);
  }
  section.BookDesignBannerSec .secdesign{
    background: url(./component/assets/rectangle.png);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 38px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    transform: translate(0px, 54px);
  }
  section.MockupDesignBannerSec .secdesign{
    background: url(./component/assets/rectangle.png);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 38px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    transform: translate(0px, 54px);
  }
  section.PackagingDesignBannerSec .secdesign{
    background: url(./component/assets/rectangle.png);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 38px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    transform: translate(0px, 54px);
  }
  section.StationaryDesignBannerSec .secdesign{
    background: url(./component/assets/rectangle.png);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 38px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    transform: translate(0px, 54px);
  }
section.ppcBannerSec .secdesign{
  background: url(./component/assets/rectangle.png);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  transform: translate(0px, 54px);
}
section.websiteBannerSec .secdesign{
  background: url(./component/assets/rectangle.png);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  transform: translate(0px, 54px);
}
section.UxDesignBannerSec .secdesign{
  background: url(./component/assets/rectangle.png);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  transform: translate(0px, 54px);
}
section.UiDesignBannerSec .secdesign{
  background: url(./component/assets/rectangle.png);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  transform: translate(0px, 54px);
}
section.CustomAppBannerSec .secdesign{
  background: url(./component/assets/rectangle.png);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  transform: translate(0px, 54px);
}
section.CustomFrontEndBannerSec .secdesign{
  background: url(./component/assets/rectangle.png);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  transform: translate(0px, 54px);
}

section.ppcBannerSec{
  height: 700px;
  background: url(./component/assets/ppcBanner-cover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.designBannerSec{
  /* height: 700px; */
  background:linear-gradient(178deg, #fff, transparent, #24E3C5);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.designBannerSec .row {
  transform: translate(0px, 70px);
}
section.designBannerSec img {
  object-fit: contain;
  width: 45%;
}
section.DigitalDesignBannerSec{
  height: 700px;
  background: url(./component/assets/digital-design-cover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.appBannerSec{
  height: 700px;
  background: url(./component/assets/mobile-app-cover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.BookDesignBannerSec{
  height: 700px;
  background: url(./component/assets/book-design-cover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.MockupDesignBannerSec{
  height: 700px;
  background: url(./component/assets/mockup-design-cover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.PackagingDesignBannerSec{
  height: 700px;
  background: url(./component/assets/packaging-design-cover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.StationaryDesignBannerSec{
  height: 700px;
  background: url(./component/assets/stationary-design-cover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.websiteBannerSec{
  height: 700px;
  background: url(./component/assets/website-development-cover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.UxDesignBannerSec{
  height: 700px;
  background: url(./component/assets/ux-design-cover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.UiDesignBannerSec{
  height: 700px;
  background: url(./component/assets/ui-design-cover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.CustomAppBannerSec{
  height: 700px;
  background: url(./component/assets/customApplication.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.CustomFrontEndBannerSec{
  height: 700px;
  background: url(./component/assets/front-end-design-cover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
/* smm */
section.smmBannerSec .secdesign{
  background: url(./component/assets/rectangle.png);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  transform: translate(0px, 54px);
}
section.smmBannerSec{
  height: 700px;
  background: url(./component/assets/smm.png);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
section.designThirdSec{
  padding: 145px 0px 50px;
  text-align: left;
}
section.designThirdSec .firstTextSec h3 span {
  color: #24E3C5;
}

section.designThirdSec .firstTextSec h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
}
section.designThirdSec .firstTextSec p {
  margin-top: 14px;
  word-spacing: 3px;
  line-height: 28px;
  font-size: 16px;
}
section.designThirdSec .firstTextSec h4 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
}

section.designThirdSec .firstTextSec h4 b {
  color: #44e4c4;
}
section.designFourthSec{
  padding: 245px 0px 50px;
  text-align: left;
}
section.designFourthSec .firstTextSec h3 span {
  color: #24E3C5;
}

section.designFourthSec .firstTextSec h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 36px;
}
section.designFourthSec .firstTextSec p {
  margin-top: 14px;
  word-spacing: 3px;
  line-height: 28px;
  font-size: 20px;
}
section.designFourthSec .firstTextSec h4 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  margin: 23px 0px 0px 0px;
}

section.designFourthSec .firstTextSec h4 b {
  color: #44e4c4;
}
section.designFourthSec .firstTextSec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* thrd */
 .firstTextSecght h3 span {
  color: #24E3C5;
}

 .firstTextSecght h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 36px;
}
 .firstTextSecght p {
  margin-top: 14px;
  word-spacing: 3px;
  line-height: 28px;
  font-size: 20px;
}
 .firstTextSecght h4 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  margin: 23px 0px 0px 0px;
}

 .firstTextSecght h4 b {
  color: #44e4c4;
}
 .firstTextSecght {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
section.materialDigital {
  background: #ffffff;
  color: white;
  padding: 65px 0px;
}

section.materialDigital .colEight{
  text-align: left;
  padding: 50px 0px;
}
section.materialDigital .colEight h3 span{
  color: #44e4c4;
}
section.materialDigital .colEight h3 {
  font-size: 36px;
    text-transform: uppercase;
}
section.materialDigital .colEight p {
  margin-top: 14px;
  word-spacing: 1px;
  line-height: 28px;
  font-size: 18px;
}
section.materialDigital .box-digital {
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  /* transition:  opacity 0.5s ease-in-out, transform 0.5s ease-in-out; */
  transition: all 0.8s ease-in-out;
  justify-content: center;
  align-items: center;
  gap: 14px;
}
section.materialDigital .box-digital img {
  width: 24%;
}
section.materialDigital .box-digital p {
  margin-top: 14px;
    word-spacing: 1px;
    line-height: 28px;
    font-size: 18px;
}
section.materialDigital .box-digital h4 {
  margin: auto;
  text-transform: uppercase;
  font-size: 24px;
}
section.designSixthSec {
  padding: 65px 0px;
}
section.designSixthSec .partnerApex h2 {
  color: #24E3C5;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 50px;
}

section.designSixthSec .partnerApex h2 span {
  font-weight: 400;
  color: #4B4B4B;
}

section.designSixthSec .partnerApex p span{
  color: #24E3C5;
  font-weight: 700;
}
section.designSixthSec .partnerApex p {
  margin-top: 14px;
  word-spacing: 3px;
  line-height: 28px;
  font-size: 20px;
}

section.designseventhSec {
  padding: 65px 0px;
  background: url(./component/assets/footerBanner.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  height: 80vh;
}
section.designseventhSec .partnerApex h2 {
  color: #24E3C5;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 42px;
  filter: drop-shadow(1px -6px 2px gray);
}

section.designseventhSec .partnerApex h2 span {
  font-weight: 400;
  color: #4B4B4B;
}

section.designseventhSec .partnerApex p span{
  color: #24E3C5;
  font-weight: 700;
}
section.designseventhSec .partnerApex p {
  margin-top: 14px;
  word-spacing: 3px;
  line-height: 28px;
  font-size: 16px;
  text-transform: capitalize;
}
.div-border-line {
    width: 100%;
    border-bottom: 1px solid;
}

.DevelopingLasting {
  width: max-content;
  text-align: left;
  background: #24E3C5;
  padding: 30px 50px 30px 15px;
  margin-top: 30px;
  font-size: 18px;
  color: #4F4F4F; 
}
.DevelopingLasting:hover {
  border-top-right-radius:  60px;
}
.col-lg-4.bg-darkShadow {
  background: #D9D9D9;
  color: black;
  padding: 50px 27px;
}
.col-lg-4.bg-darkShadow-left:hover {
  border-bottom-left-radius: 60px;
}
.col-lg-4.bg-darkShadow-right:hover {
  border-bottom-right-radius: 60px;
}
.col-lg-4.bg-darkShadow h2 {
  font-size: 22px;
  text-transform: uppercase;
}

.col-lg-4.bg-darkShadow p {
  margin-top: 14px;
  word-spacing: 1px;
  line-height: 28px;
  font-size: 16px;
}
.col-lg-4.bg-lightShadow {
  background: #fff;
  color: black;
  padding: 50px 27px;
}
.col-lg-4.bg-lightShadow:hover {
  border-top-left-radius: 60px;
}

.col-lg-4.bg-lightShadow h2 {
  font-size: 22px;
  text-transform: uppercase;
}

.col-lg-4.bg-lightShadow p {
  margin-top: 14px;
  word-spacing: 1px;
  line-height: 28px;
  font-size: 16px;
}

.uiForthSec-btm {
  margin: 40px 0;
}

.uiForthSec-btm-lft {
  background-color: #00e2c5;
  margin-top: 30px;
  color: #232323;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top-right-radius: 125px;
  align-items: center;
  text-align: left;
}
.uiForthSec-btm-lft h5 {
  font-size: 30px;
}
.uiForthSec-btm-lft li {
  font-size: 20px;
}

.uiForthSec-btm-rgt {
  background-color: #757070;
  color: #ffff;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top-right-radius: 125px;
  align-items: center;
  text-align: left;
}
.uiForthSec-btm-rgt h5 {
  font-size: 30px;
}
.uiForthSec-btm-rgt li {
  font-size: 20px;
}

.uimaterialDigital h3{
  font-size: 36px;
  text-transform: uppercase;
}
.uimaterialDigital h3 span{
  color: #00e2c5;
}

.ui-fifthSec-btm-sec {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  color: #000;
}
.ui-fifthSec-btm-sec-div-1 {
  background-color: #d9d9d9;
  width: 30%;
  height: auto;
  padding: 30px 15px;
}
.ui-fifthSec-btm-sec-div-2 {
  background-color: #ffff;
  width: 30%;
  height: auto;
  padding: 30px 15px;
}
.ui-fifthSec-btm-sec-div-3 {
  background-color: #d9d9d9;
  width: 30%;
  height: auto;
  padding: 30px 15px;
}
.ui-fifthSec-btm-sec-div-1 h6{
  font-size: 25px;
  font-weight: 600;
}
.ui-fifthSec-btm-sec-div-2 h6{
  font-size: 25px;
  font-weight: 600;
}
.ui-fifthSec-btm-sec-div-3 h6{
  font-weight: 600;
  font-size: 25px;
}
.about_main{
  padding-top: 50px;
}
.about_main h1 {
  text-align: start; 
  font-family: 'Inter', sans-serif; 
  font-size: 100px; 
  font-weight: 400;
  color: #030510;
}
 .color_set {
  color: #00e2c5;
}
.about_main p{
  padding: 80px 0;
  color: #404040;
    font-size: 22px;
    font-family: 'Inter', sans-serif; 
    text-align: start; 
}
.second_sec_about {
    background: linear-gradient(1deg, #00e2c5d6, transparent), url(./component/assets/bannerimage.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    height: 800px;
    display: flex;
    align-items: end;
    justify-content: start;
}
.second_sec_head{
  transform: translate(0px,10px);
}
.second_sec_head h1{
  font-family: 'Inter', sans-serif; 
  color: #fff;
  font-size: 110px;
  text-align: start;
    font-weight: 300;
}
.third_sec_about{
  background: #00e2c596;
  box-shadow: inset 1px 20px 20px 20px #00e2c596;
}
.third_sec_text p{
  color: #fff;
  text-align: start;
  font-size: 35px;
  font-family: 'Inter', sans-serif; 
  padding-top: 10px;
  transform: translate(0px,120px);

}
.third_sec_texted p{
  color: #fff;
  text-align: start;
  font-size: 25px;
  font-family: 'Inter', sans-serif; 
  padding-top: 10px;
  transform: translate(0px,120px);
}
.third_sec_head h1{
  color: #fff;
  font-size: 75px;
  font-family: 'Inter', sans-serif; 
  padding-top: 10px;
  /* transform: translate(0px,140px); */
  padding-bottom: 50px;
  padding-top: 150px;
  text-align: start;


}
.foursec_card{
  background:#030511;
}
.card_sec {
  display: flex;
  justify-content: space-around;
  gap: 50px;
  margin-top: 40px;
  padding-bottom: 100px;
}
.card_sec p {
  color: #fff;
  text-align: start;
  font-size: 24px;
  font-family: 'Inter', sans-serif; 
}
.prah_sec p{
  color: #fff;
  text-align: start;
  font-size: 36px;
  font-family: 'Inter', sans-serif;
  padding-bottom: 100px;
}

.auto_slider_container {
 
  width: 100%;
  display: flex;
  margin: auto;
  padding: 40px 0;
  overflow: hidden;
}

.auto_slider {
  padding: 0;
  margin: 0;
  list-style: circle;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotation 30s linear infinite;
    
}
section#second.sec-two .auto_slider li {
  background: linear-gradient(357deg, #24E3C5, transparent);
}



.auto_slider li {
  /* background: linear-gradient(357deg, #24E3C5, transparent); */
  font: 60px/1.2 "Roboto";
  padding: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  height: 300px;
  width: 300px;
  margin: 0 15px;
  border-radius: 25px;
  color: #0f0f0f; /* Set the text color to white */
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  text-shadow: 1px 1px hsla(0, 0%, 100%, .6), -1px 1px hsla(0, 0%, 100%, .6), 1px -1px hsla(0, 0%, 100%, .6), -1px -1px hsla(0, 0%, 100%, .6);
}
.auto_slider li img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.auto_slider li:hover {
  animation-play-state: paused;
  color: #ffff;
  transform: scale(1.05);
}

@keyframes rotation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.popup-overlay-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content-image {
  position: relative;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.popup-content-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.close-button-image {
  border-radius: 100px;
  position: absolute;
  top: -24px;
  right: 58px;
  font-size: 24px;
  color: #000;
  cursor: pointer;
  padding: 10px;
  background: #24e3c5;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-navigation-image {
  display: flex;
  /* margin-top: 10px; */
  position: absolute;
  top: 50%;
  justify-content: space-between;
  width: 100%;
}

.popup-navigation-image button {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s;
}

.popup-navigation-image button:hover {
  color: #24e3c5;
}

/* Slide animations */
.swiper-slide {
  opacity: 0.2;
  transform: translateX(200px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.swiper-slide-active {
  opacity: 1;
  transform: translateX(200);
}
.container.PackageSlick .slick-list{
  margin: 27px 0px;
  height: auto !important;
}
.container.PackageSlick .slick-track .slick-slide {padding: 5px;}
h1.main-title{
  color: #4B4B4B;
}
h1.main-title span{
color: #24E3C5;
}
section#portfolio {}

section#portfolio .card.h-100 {
    text-align: left;
    border-radius: 20px;
    margin: 20px 0px;
    border: 0px;
}

section#portfolio .card.h-100 .card-body {
    padding: 24px 0px;
}

section#portfolio .card.h-100 .card-body h5 {
    color: #4F4F4F;
    text-transform: capitalize;
}

section#portfolio .card.h-100 .card-body h4.card-title {
    color: #24E3C5;
    font-size: 38px;
    text-transform: uppercase;
    font-weight: 600;
}

section#portfolio .card.h-100 .card-body p.card-text {
    color: #4F4F4F;
    font-size: 16px;
    line-height: 31px;
    word-spacing: 5px;
}
.navbar-toggler-icon{
  
  filter: brightness(0.5) !important;
}
.footer-payment-sec {
  padding: 37px 0px;
  text-align: center;
}

.footer-payment-sec h5 {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 49px;
  font-weight: 400;
  color: #030510;
}

.footer-payment-sec h5 span {
  color: #00e2c5;
}
@keyframes blinker {
  0% {
      opacity: 1;
  }

  49% {
      opacity: 1;
  }

  60% {
      opacity: 0;
  }

  99% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }

}

/* contact us */
.contact-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: #fafafa;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-container .form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 0;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: #39c0a5;
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #149279);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #1abc9c;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

.contact-container form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 5px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #1abc9c;
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
}

.btn:hover {
  background-color: transparent;
  color: #fff;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #1abc9c;
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  text-align: left;
  position: relative;
}

.contact-info .title {
  color: #1abc9c;
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.information i {
  color: #1ABC9C;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #1abc9c, #149279);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #1abc9c;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  /* animation: blinker 1s linear 0s infinite normal none running; */
  border-radius: 50%;
  background: linear-gradient(to bottom, #39c0a5, #44e4c4);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}

@keyframes updown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes afterUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust as needed */
  }
}

section#home-sec1  img {
  animation: updown 2s ease-in-out infinite;
}

/* section#home-sec1 .col-6 .side-image:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 36px solid transparent;
  border-right: 109px solid #44e4c4;
  border-bottom: 9px solid transparent;
  right: 8%;
  animation: afterUpDown 2s ease-in-out infinite;
} */
/* section#home-sec1 .col-6 .side-image::before {
  content: '';
  position: absolute;
  width: 459px;
  height: 459px;
  border-radius: 100%;
  background: #44e4c4;
  animation: afterUpDown 2s ease-in-out infinite;
} */
/* Hide the popup by default */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content .popup-form{
  background: white;
    padding: 33px 37px;
    position: relative;
}
.popup-content {
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 800px;
  height: 533px;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background: url(./component/assets/bgsquare.png), linear-gradient(45deg, #3bc0b2, #d4d7d7);
  padding: 0px 0 0px 0;
  background-size: contain;
  z-index: 99;
  display: flex;
  justify-content: end;
  align-items: end;
  background-repeat: no-repeat;
  background-position: right;
}

.popup-content h2 {
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
}

.popup-content p {
  margin-bottom: 25px;
  color: #555;
}

.popup-content input,
.popup-content textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.popup-content button {
  background-color: #44e4c4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-content .animatedPopupImage img{
  animation: afterUpDown 2s ease-in-out infinite;
}
.popup-content button:hover {
  background-color: #3cbca2;
}

/* Close button styling */
.close-popup {
  position: absolute;
  top: -11px;
  right: -11px;
  font-size: 22px;
  color: #333;
  cursor: pointer;
  background: #ffffff;
  height: 30px;
  width: 30px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 29px;
  color: #000000;
}

.close-popup:hover {
  color: #888;
}
.popup-content .animatedPopupImage img {
  width: 100% !important;
}

/* home services */
.services .card {
  position: relative;
  z-index: 1;
  background: #ffffff73;
  border-top: 3px solid  #24e3c5;
}

.services i {
  color:  #24e3c5;
}
section.services.py-5 h2.webcolor span {
  color: #4f4f4f;
}
section.services.py-5 h2.webcolor {
  color: #24e3c5;
  position: relative;
  z-index: 1;
}
.card > .anim-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color:  #24e3c5;
  transition: height 0.3s ease;
}

.card:hover .anim-layer {
  height: 100%;
}

.card:hover h2, .card:hover svg, .card:hover p {
  position: relative;
  z-index: 1;
  color: #fff;
}

.card svg {
  background-color: #ffffff;
  width: 80px;
  height: 80px;
  
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  margin-bottom: 20px;
}

.card:hover svg {
  color:  #24e3c5;
}
.container.footer ul.useful-links {
  display: flex;
  gap: 16px;
}


.container.footer ul.useful-links li {
  background: #ffffff3d;
  margin: 0px 0px;
  padding: 2px 10px;
  font-size: var(--bs-body-font-size);
  border: 1px solid;
  font-size: 14px;
}


/* aboutus */
section#home-sec1 img {
  display: table;
  margin: auto;
  width: 80%;
}

/* section#home-sec1 .col-6 h1, section#home-sec1 .col-6 p,section#intro-section .row,#proven-ecommerce,.row-text,section#growth-success .col-6,#complete-solutions  {
  text-align: center !important;  
} */
@media (max-width: 480px) {
	
	
	section#home-sec1 .bg {
		padding: 20px 20px 10px !important;
		margin-left: 0px !important;
	}
}
/* section#home-sec1 .headings-home h1, section#home-sec1 .headings-home p {
  text-align: center !important;
} */
section#home-sec1 .buttons ul li a {
  padding: 10px 20px;
  font-size: 14px;
}
section#home-sec1 .buttons ul {
  display: flex;
  margin: 40px 0;
  gap: 13px;
  padding: 0;
}

	section#home-sec1 .col-6 img {
        width: 80%;
    }
    /* section#home-sec1 .col-6 img {
      width: 80%;
  } */
  /* NEW HOME CSS */
section#home-sec1 .headings-home p.rated {
  font-weight: bold;
  color: #ede8e28a;
  padding: 13px 0px;
}
section#home-sec1 .headings-home p {
  color: #000;
  padding: 0;
  margin: 0;
text-align: left;
}
section#home-sec1 img {
  margin: 18px 0 0px 0;
}
section#home-sec1 .headings-home h1 b {
  color: #000;
}
section#home-sec1 .headings-home h1 {
  color: #00e2c5;
  font-size: 44px;
  line-height: 60px;
  text-align: left;
  padding: 0;
}
#home-sec1 {
  background-image: linear-gradient(45deg, #ffffff, transparent);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 32px 0px;
}
section#home-sec1 .home-heading1 {
  font-size: 45px !important;
  line-height: 60px !important;
}
section#about-us-main-section .heading-text h2 {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0;
}
/* section#intro-section::before,section#proven-ecommerce::before,section#about-us-main-section::before
,section#testimonials-sections::before {
    content: url(https://www.ecommonks.com/images/shwd.png);
    position: absolute;
    top: -50px;
    left: 0;
	animation: pulse 5s infinite;
    transform: rotate(180deg);
} */
/* ABOUT US CSS */
section#about-us-main-section {
  margin-bottom: 0;
  background: #000000;
}
section#about-us-main-section .heading-text h1 {
  font-size: 40px;
  line-height: 50px;
}

.about-banner-buttons ul {
  text-align: center;
}
section#about-us-main-section .about-banner-buttons ul {
  margin: 0;
  padding: 0;
  text-align: left;
}

.about-banner-buttons ul li {
  display: inline-block;
  padding: 10px;
}
.about-banner-buttons .brand-button h6 a {
  padding: 12px 54px;
}
body.about-us section#header p {
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 148.3%;
  text-align: center;
  color: #00e2c5;
}
section#about-us-main-section img:hover {
  transform: translate3d(30px, 0px, 60px);
}

section#about-us-main-section img {
  transition: .9s;
}
/* about us css end */
/* MILESTONE CSS START */
section#milestone-section .text-mile-area .heading-text h1 b {
  font-size: 85px;
  line-height: 85px;
}
section#milestone-section .text-mile-area .heading-text h1 {
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 400;
  font-size: 55px;
  line-height: 64px;
}
section#milestone-section .text-mile-area img {
  width: 87%;
  text-align: center;
  margin: 0 auto;
  display: table;
}
section#milestone-section .mile-function-code h1 span {
  color: #fff;
}

section#milestone-section .mile-function-code h1 {
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 105.8%;
  text-align: center;
  color: #43e2c2!important;
}
section#milestone-section .mile-function-code h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 105.8%;
  text-align: center;
  color: #E0E0E0;
  font-family: 'Anek Tamil';
}
section#milestone-section .mile-function-code {
  text-align: center;
}
section#milestone-section .mile-function-code .mileswithtext:hover h1 {
  color: #fff;
}
section#milestone-section .mile-function-code .mileswithtext:hover {
  background: linear-gradient(91deg, #43e2c2 26.76%, #43e2c2 75.8%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  transition: .9s;
  transform: translateY(10px);
}
section#milestone-section .mision-vision .mis-vis p {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}

section#milestone-section .mision-vision .mis-vis h2 {
  color: #fff;
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
}
section#milestone-section .mision-vision .mis-vis {
  background: #0d0d0d;
  box-shadow: 0px 7px 3px rgba(0, 0, 0, 0.09);
  border-radius: 35px;
  border: 2px solid #43e2c2;
  padding: 40px 50px;
  margin: 0 13px;
}
section#milestone-section .mision-vision .mis-vis h2 b {
  background: #43e2c2;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
section#milestone-section .mile-function-code .mileswithtext {
  border: 2px solid #43e2c2;
  /* background-image: url(https://www.ecommonks.com/images/hmebg.jpg); */
  box-shadow: 0px 7px 3px rgba(0, 0, 0, 0.09);
  border-radius: 30px;
  padding: 52px 0px;margin: 10px 0px 0px;
  transition: .9s;
}
section#milestone-section .mile-function-code .mileswithtext h2 {
  font-size: 34px;
  color: #fff;
}
section#milestone-section {
  margin-bottom: 0;
  background-image: url(./component/assets/bg1s.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* filter: hue-rotate(128deg); */
}
/* MILESTONE CSS END */

/* section#intro-section::before, section#proven-ecommerce::before, section#about-us-main-section::before, section#testimonials-sections::before {
  content: url(./component/assets/sss.png);
  position: absolute;
  top: -50px;
  left: 0;
  animation: pulse 5s infinite;
  transform: rotate(180deg);
} */
@keyframes pulse2 {
0% {
  box-shadow: 0 0 0 0px #ffffff;
}
100% {
  box-shadow: 0 0 0 8px #000000;
}
}
@keyframes pulse {
0% {
  opacity: 0;
}

50% {
  opacity: 1;
}
100% {
  opacity: 0;
}
}
/* what can we gallery */
section#what-canwe-about .gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}
section#what-canwe-about .gallery-item:hover {
  transform: scale(0.9);
  transition: .9s;
}

section#what-canwe-about .gallery-item {
  flex-basis: 24.7%;
  margin-bottom: 6px;
  opacity: 1 !important;
  cursor: pointer;
}
section#what-canwe-about .gallery-about-section h6 {
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 700;
  font-size: 167px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.04em;
  color: rgba(166, 166, 166, 0.21);
}
/* what can we gallery css end */
/* CONNECT SEC CALL TO ACTION START */
.my-connect-inner img {
  position: absolute;
  margin-top: -170px;
}
.my-connect-inner img:hover {
  transform: scale(1.1);
  filter: hue-rotate(-75deg);
}

.my-connect-inner img {
  transition: .9s;
}
.my-connect-inner .my-custom {
  margin-bottom: -114px;
}
.my-connect-inner {
  background: #232323;
  border: 1px solid #535353;
  box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  padding: 130px 70px 70px 70px;
}
section#call-toaction-section h2 {
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 32px;
}
.my-connect-inner .about-banner-buttons ul {
  text-align: left;
  padding: 0;
}
section#call-toaction-section h2 b {
  background: #43e2c2;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

}
#about-us-main-section .heading-text h3 {
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 103.8%;
  color: #FFFFFF;
  text-align: left;
}
#about-us-main-section .heading-text p{
  text-align: left;
}
#about-us-main-section .heading-text h2 {
  color: #43e2c2;
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 103.8%;
  text-align: left;
}
.about-banner-buttons .brand-button h6 a {
  padding: 12px 54px;
}

.brand-button h6 a {
  padding: 13px 29px;
  text-decoration: none;
  color: #00e2c5;
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 105.8%;
  transition: .9s;
  border: solid;
  border-radius: 26px;
  border-color: #00e2c5;
  animation: pulse-animation 2s infinite;
}
.brand-button {
  padding-top: 20px !important;
}
#milestone-section .heading-text h2 {
  color: #fff;
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 103.8%;
}
#milestone-section .heading-text h2 b {
  background: #43e2c2;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.whysches {
  padding: 40px 0px;
  text-align: left;
  color: #fff;
}
.whysches h1 {
  color: #fff;
  font-weight: bold;
}
.whysches h1 b {
  color: #43e2c2;
}
@keyframes pulse-animation {
0% {
  box-shadow: 0 0 0 0px #00e2c5;
}
100% {
  box-shadow: 0 0 0 8px #00e2c494;
}
}
.whysches .buttons ul li a {
  background: #ff10;
  padding: 12px 35px;
  text-decoration: unset;
  color: #fff;
  border-radius: 30px;
  border: 3px solid #43e2c2;
  font-family: 'Anek Tamil';
  animation: pulse-animation 2s infinite;
}
.whysches .buttons ul li {
  list-style: none;
}
.whysches .buttons ul {
  display: flex;
  margin: 40px 0;
  gap: 13px;
  padding: 0;
}
.whysches a {
  background: #43e2c2 !important;
  color: #000 !important;
}
section#milestone-section .mision-vision .mis-vis {
  background: #0d0d0d;
  box-shadow: 0px 7px 3px rgba(0, 0, 0, 0.09);
  border-radius: 35px;
  border: 2px solid #43e2c2;
  padding: 40px 50px;
  margin: 0 13px;
}

.mision-vision .msn12 {
  background: #43e2c2 !important;
}
section#milestone-section {
  text-align: left;
}
section#milestone-section .mision-vision .msn12 h2 b {
  background: #000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
section#milestone-section .mision-vision .msn12 p {
  color: #000;
  text-shadow: 0px 0 0 BLACK;
}
section#testimonials-sections .slider.slider-single {
  margin: 105px 29px!important;
  padding: 20px;
}
section#testimonials-sections .testimonial-quote{
  right:0!important;
}
/* TESTIMONIALS START */
section#testimonials-sections #page .slick-track .testimonial-text {
  text-align: center;
  color: #fff;
}

section#testimonials-sections .testimonial-text h3 {
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 54px;
  color: #FFFFFF;
}
section#testimonials-sections .slider.slider-single {
  margin: 60px 100px;
  border: 2px solid #51b27e;
  padding: 46px 20px;
  background: #1a1818;
  box-shadow: 0px 6px 21px rgba(0, 0, 0, 0.14);
  border-radius: 25px;
}
section#testimonials-sections .slider.slider-nav.slick-initialized.slick-slider {
  margin: 40px 0;
  padding: 0 0;
}
section#testimonials-sections .slick-slide img {
  width: 130px;
  margin: 0 auto;
}
#basicAccordion {
  padding: 0 100px;
}
section#testimonials-sections .slick-slide.is-active.slick-active h3 span img {
  border-radius: 50%;
  border: 2px solid #43e2c2;
}
section#testimonials-sections .testimonial-quote {
  position: absolute;
  right: 340px;
  z-index: 111111;
}
section#testimonials-sections button.slick-prev.slick-arrow {
  background-image: url(./component/assets/leftarrow.webp);
  background-size: 100% 100%;
  padding: 30px 30px;
  z-index: 1111;
position: absolute;
/* filter: hue-rotate(128deg); */
  left: -90px;
}
section#testimonials-sections .slider-nav button.slick-prev.slick-arrow,
section#testimonials-sections .slider-nav button.slick-next.slick-arrow {
  display: none !important;
}
.slick-next:before, .slick-prev:before {
  display: none;
}
section#testimonials-sections button.slick-next.slick-arrow {
  background-image: url(./component/assets/rightarrow.webp);
  background-size: 100% 100%;
  padding: 30px 30px;
  z-index: 1111;
  position: absolute;
  right: -90px;
  /* filter: hue-rotate(128deg); */
}
section#testimonials-sections {
  position: relative;
  background: #0f0e0e;
  color: white;
}
#testimonials-sections h2 {
  color: white;
}
#testimonials-sections .heading-text h3 {
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 103.8%;
  color: #FFFFFF;
}
section#testimonials-sections .heading-text h3 b {
  color: #00e2c5;
  font-weight: bold;
}
section#testimonials-sections #page .column.small-11.small-centered {
  /* margin: 60px 100px; */
  border: 2px solid #1ad7bf;
  padding: 46px 20px;
  background: #1a1818;
  box-shadow: 0px 6px 21px rgba(0, 0, 0, 0.14);
  border-radius: 25px;
}
.trhstpiot {
  /* position: absolute; */
  bottom: 70px;
  right: 140px;
}
#home-sec1 .buttons ul li {
  list-style: none;
}
#home-sec1 .buttons ul li a {
  background: #ff10;
  padding: 12px 35px;
  text-decoration: unset;
  color: #000;
  border-radius: 30px;
  border: 3px solid #00e2c5;
  font-family: 'Anek Tamil';
  animation: pulse-animation 2s infinite;
}
#home-sec1 .buttons ul {
  display: flex;
  margin: 40px 0;
  gap: 13px;
  padding: 0;
}
.header-container {
  background: #24E3C5;
  padding: 18px 0px;
}

.header-container .header-top {
  display: flex;
  justify-content: space-between;
}

.header-container .header-top h3 {
  font-size: 15px;
  margin: auto;
  color: black;
  display: flex;
  gap: 7px;
}
a.header-phone {
  text-decoration: auto !important;
}
a.header-email {
  text-decoration: auto !important;
}

@keyframes swirl {
  0% {
      width: 0%;
  }

  100% {
      width: 100%;
  }
}

@keyframes draw {
  to {
      stroke-dashoffset: 0;
  }
}
.swirl path {
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  stroke: #44e4c4;
  animation: draw 3s ease-out infinite;
}
section.sec-three2 svg {
  position: absolute;
  display: block;
  margin-top: -164px;
  z-index: 0;
  rotate: 210deg;
  height: min-content;
}
section.sec-three svg {
  position: absolute;
  display: block;
  margin-top: -164px;
  z-index: 0;
}

#craouselContainer.swiper-container {
  border-radius: 5px;
  width: 70vw;
  overflow: hidden;
  height: fit-content;
  height: fit-content;
  font-size: 50px;
  position: relative;
  padding-bottom: 50px;
  font-family: sans-serif;
}

#craouselContainer .swiper-slide {
  width: auto;
  height: fit-content;
  display: flex;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px;
  font-family: Nunito;
  background-color: white;

}

#craouselContainer .swiper-slide .ImgHolder {
  background-color: #4361ee;
  display: flex;
  width: 100%;
  height: auto;
  padding: 30px;
  border-bottom-left-radius: 20px;

}

#craouselContainer .ContentHolder::before {
  background-color: #4361ee;
  content: '';
  width: 50px;
  height: 50px;
  position: absolute;
  top: -5px;
  z-index: -1;
  right: 0px;
}

#craouselContainer .ContentHolder {
  position: relative;
  padding: 10px;
  background-color: white;
  border-top-right-radius: 20px;
}

#craouselContainer .swiper-pagination-bullet {
  background-color: white;
  opacity: 1;
  border: 1px solid #4361ee;
}

#craouselContainer .swiper-pagination-bullet-active {
  background-color: #4361ee;
}

#craouselContainer .swiper-button {
  border: 1px solid white;
}

#craouselContainer .swiper-slide img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
  object-fit: cover;
  border: 3px solid #4361ee;
  outline: 2px solid white;
}

#craouselContainer .swiper-slide h3 {
  font-size: 1.1rem;
  text-align: center;
  font-weight: bold;
}

#craouselContainer .swiper-slide p {
  font-size: 0.9rem;
  padding: 5px;
}

@media (max-width:638px) {
  #craouselContainer .swiper-container {
    width: 100%;
  }
}

@media (max-width:500px) {
  #craouselContainer .swiper-container {
    width: 70vw;
  }
}

@media (max-width:300px) {
  #craouselContainer .swiper-container {
    width: 100%;
  }

  #craouselContainer .swiper-slide {
    border-radius: 0px;
  }

  #craouselContainer .swiper-container .ImgHolder {
    border-radius: 0px;
  }
  #craouselContainer .ContentHolder{
    border-radius: 0px;
  }
}
@keyframes light {
  0% { top:600px; left:0%;}
  25% { top:300px; left:22.5%;}
  37.5% { top:200px; left:33%;}
  50% { top:210px; left:45%;}
  62.5% { top:200px; left:56%;}
  75% { top:300px; left:67.5%;}
  100% { top:600px; left:90%;}
}

@keyframes shadow {
  0% { background-color:#fff;}
  100% { background-color:#555;}
}

@keyframes topleftshadow {
  0% { border-left-color:#999;}
  50% { border-left-color:#fff;}
  100% { border-left-color:#999;}
}

@keyframes toprightshadow {
  0% { border-right-color:#999;}
  50% { border-right-color:#fff;}
  100% { border-right-color:#999;}
}


#light {
  background:radial-gradient(ellipse at center,#fff 0%, #ffd 10%, #22222200  40%);
  width:100px;
  height:100px;
  position:absolute;
  z-index:10;
  -webkit-animation:light 6s linear infinite alternate;
}

/* .full-width-box .menu_container .menu_items .boxerstate .resp-submenu:nth-child(2){
  text-align: left;
} */
 /* Loader styles */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #46857b;
  z-index: 9999;
}

.loader img {
  /* width: 80px;
  height: 80px; */
  animation: pulse 2s ease-in-out infinite;
}

.loader .buffer-ring {
  position: absolute;
  width: 226px;
  height: 226px;
  border: 5px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

.topBotomBordersOut a:before, .topBotomBordersOut a:after
{
    position: absolute;
    left: 0px;
    width: 100%;
    height: 2px;
    background: #44e4c4;
    content: "";
    opacity: 0;
    transition: all 0.3s;
}

.topBotomBordersOut a:before
{
    top: 0px;
    transform: translateY(10px);
}

.topBotomBordersOut a:after
{
    bottom: 0px;
    transform: translateY(-10px);
}

.topBotomBordersOut a:hover:before, .topBotomBordersOut a:hover:after
{
    opacity: 1;
    transform: translateY(0px);
}



/* Main heading for card's front cover */
.card-front__heading {
  font-size: 1.5rem;
  margin-top: .25rem;
}

/* Main heading for inside page */
.inside-page__heading { 
  /* padding-bottom: 1rem;  */
  width: 100%;
  margin: auto;
}

/* Mixed */

/* For both inside page's main heading and 'view me' text on card front cover */
.inside-page__heading,
.card-front__text-view {
  font-size: 1.3rem;
  font-weight: 800;
  /* margin-top: .2rem; */
}

.inside-page__heading--city,
.card-front__text-view--city { color: #24E3C5; }

.inside-page__heading--ski,
.card-front__text-view--ski { color: #24E3C5; }

.inside-page__heading--beach,
.card-front__text-view--beach { color: #24E3C5; }

.inside-page__heading--camping,
.card-front__text-view--camping { color: #24E3C5; }

/* Front cover */

.card-front__tp { color: #fafbfa; }

/* For pricing text on card front cover */
.card-front__text-price {
  font-size: 1.2rem;
  margin-top: -.2rem;
}

/* Back cover */

/* For inside page's body text */
.inside-page__text {
  color: #333;
}

/* Icons ===========================================*/

.card-front__icon {
  fill: #fafbfa;
  font-size: 3vw;
  height: 3.25rem;
  margin-top: -.5rem;
  width: 3.25rem;
}

/* Buttons =================================================*/

.inside-page__btn {
  background-color: transparent;
  border: 3px solid;
  border-radius: .5rem;
  font-size: 1.2rem;
  font-weight: 600;
  /* margin-top: 2rem; */
  overflow: hidden;
  padding: .7rem .75rem;
  position: relative;
  text-decoration: none;
  transition: all .3s ease;
  width: 90%;
  z-index: 10;
}

.inside-page__btn::before { 
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scaleY(0);
  transition: all .3s ease;
  width: 100%;
  z-index: -1;
}

.inside-page__btn--city { 
  border-color: #24E3C5;
  color: #24E3C5;
}

.inside-page__btn--city::before { 
  background-color: #24E3C5;
}

.inside-page__btn--ski { 
  border-color: #279eb2;
  color: #279eb2;
}

.inside-page__btn--ski::before { 
  background-color: #279eb2;
}

.inside-page__btn--beach { 
  border-color: #fa7f67;
  color: #fa7f67;
}

.inside-page__btn--beach::before { 
  background-color: #fa7f67;
}

.inside-page__btn--camping { 
  border-color: #00b97d;
  color: #00b97d;
}

.inside-page__btn--camping::before { 
  background-color: #00b97d;
}

.inside-page__btn:hover { 
  color: #fafbfa;
}

.inside-page__btn:hover::before { 
  transform: scaleY(1);
}

/* Card ============================================*/


/* A container to hold the flip card and the inside page */
.cardMain {
  background-color: rgba(0,0,0, .05);
  box-shadow: -.1rem 1.7rem 6.6rem -3.2rem rgba(0,0,0,0.5);
  height: 15rem;
  position: relative;
  transition: all 1s ease;
  width: 15rem;
  
  margin-top: 24px;
}

/* Flip card - covering both the front and inside front page */

/* An outer container to hold the flip card. This excludes the inside page */
.flip-card {
  height: 15rem;
  perspective: 100rem;
  position: absolute;
  right: 0;
  transition: all 1s ease;
  visibility: hidden;
  width: 15rem;
  z-index: 100;
}

/* The outer container's visibility is set to hidden. This is to make everything within the container NOT set to hidden  */
/* This is done so content in the inside page can be selected */
.flip-card > * {
  visibility: visible;
}

/* An inner container to hold the flip card. This excludes the inside page */
.flip-card__container {
  height: 100%;
  position: absolute;
  right: 0;
  transform-origin: left;
  transform-style: preserve-3d;
  transition: all 1s ease;
  width: 100%;
}

.card-front,
.card-back {
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Styling for the front side of the flip card */

/* container for the front side */
.card-front {
  background-color: #fafbfa;
  height: 15rem;
  width: 15rem;
}

/* Front side's top section */
.card-front__tp {
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
  display: flex;
  flex-direction: column;
  height: 12rem;
  justify-content: center;
  padding: .75rem;
}

.card-front__tp--city {
  background: linear-gradient(to bottom, #3ac795, #44e4c4);
}

.card-front__tp--ski {
  background: linear-gradient(
    to bottom,
    #47c2d7,
    #279eb2
  );
}

.card-front__tp--beach {
  background: linear-gradient(
    to bottom,
    #fb9b88,
    #f86647
  );
}

.card-front__tp--camping {
  background: linear-gradient(
    to bottom,
    #00db93,
    #00b97d
  );
}

/* Front card's bottom section */
.card-front__bt {
  align-items: center;
  display: flex;
  justify-content: center;
}

/* Styling for the back side of the flip card */

.card-back {
  background-color: #fafbfa;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-back img {
  width: 50%;
}
/* Specifically targeting the <video> element */
.video__container {
  clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
  height: auto;
  min-height: 100%;
  object-fit: cover;
  width: 100%;
}

/* Inside page */

.inside-page {
  background-color: #fafbfa;
  box-shadow: inset 20rem 0px 5rem -2.5rem rgba(0,0,0,0.25);
  height: 100%;
  padding: 1rem;
  position: absolute;
  right: 0;
  transition: all 1s ease;
  width: 15rem;
  z-index: 1;
}

.inside-page__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center; 
  width: 100%;
}

/* Functionality ====================================*/

/* This is to keep the card centered (within its container) when opened */
.cardMain:hover {
  box-shadow:
  -.1rem 1.7rem 6.6rem -3.2rem rgba(0,0,0,0.75);
  width: 23rem;
  cursor: pointer;
}

/* When the card is hovered, the flip card container will rotate */
.cardMain:hover .flip-card__container {
  transform: rotateY(-180deg);
}

/* When the card is hovered, the shadow on the inside page will shrink to the left */
.cardMain:hover .inside-page {
  box-shadow: inset 1rem 0px 5rem -2.5rem rgba(0,0,0,0.1);
}
.col-lg-6.smm img {
  filter: hue-rotate(277deg);
  object-fit: contain;
  width: 100%;
}
.toc.fixed {
  position: fixed;
  top: 0;
  z-index: 1000;
}

.video-container .second-para {
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transform-origin: center center;
  perspective: 1000px;
}

.scroll-text {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  text-align: center;
  transition: transform 0.3s ease-out;
}
.portfolio-text-div {
  border: 2px solid gray;
  padding: 26px;
  border-radius: 20px;
  transition: all 0.3s linear;
  background: #fff;
  width: 100%;
  margin-bottom: -34px;
  overflow: hidden; /* Keeps hidden content from overflowing */
}

.portfolio-text-div p {
  max-height: 0; /* Collapses the content initially */
  opacity: 0;
  overflow: hidden;
  transform: translateY(10px); /* Slight downward shift for animation */
  transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
}

.portfolio-text-div:hover p {
  max-height: 1000px; /* Large enough to accommodate long content */
  opacity: 1;
  transform: translateY(0); /* Brings the text to its original position */
  transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
}


.workwithuk{
  background: #373737;
    padding: 0px 25px;
    border-radius: 21px;
    box-shadow: 0px 0px 6px 1px gray;
    animation: pulse-animation 2s infinite;
}

.sixtySecImg .contact-form-container.open.show {
  position: relative;
  background: none;
}
.sixtySecImg .contact-form-container .contact-form {
  background: #00000000;
}
section.materialDigital .box-digital:hover {
  background: #ffffff52;
  /* padding: 19px 22px !important;  */
  border-radius: 18px;
  border: 4px solid #00e2c5;
}
.row.workwithuk {
  padding: 16px 5px;
} 

section.materialDigital .box-digital:hover h4 {
  color: #ffffff;
}

section.materialDigital .box-digital:hover p {
  color: #ffffff;
}

section.materialDigital .box-digital:hover img {
  filter: brightness(1.6);
}
#home-sec1 .side-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Testimonial.css */
.teals-testimonials .container.testmodels {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.teals-testimonials .container.testmodels .carousel {
  width: 100%;
  max-width: 800px; /* Optional: Limit the max-width for larger screens */
  margin: 0 auto;
}

.teals-testimonials .container.testmodels  .testimonials_web {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.stars {
  display: flex;
  justify-content: center;
  gap: 5px;
}

/* media Query */

@media (max-width: 1200px) and (min-width: 1024px) {
  .header-container .header-top h3 {
    font-size: 12px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 12px;

  }
  .overlay {
  padding-top: 30px;
  height: 675px !important;

  }
  .overlay .second-para h1 {
   font-size: 30px;
  }
  .overlay .second-para P {
   font-size: 20px;
  }
}

@media (max-width: 1024px) and (min-width: 786px) {
}
.footer-payment-sec .auto_slider_container ul.auto_slider li {
  padding: 0px;
  width: auto;
  height: auto;
}
.footer-payment-sec .auto_slider_container ul.auto_slider li img {
  width: 35%;
}
@media (max-width: 786px) and (min-width: 300px) {
  section#milestone-section .mision-vision .mis-vis p{
    font-size: 16px;
  }
  div#page {
    padding: 10px;
}
.trhstpiot{
  bottom: auto;
}
  section#milestone-section .mision-vision .mis-vis{
    padding: 20px 20px;
    margin: 0px 0px 20px;
  }
  .header-container {
    display: none;
  }
  nav.navbar.navbar-expand-lg.navbar-light .container {
    padding-top: 0;
    padding-bottom: 0;
}
.navbar-expand-lg .navbar-nav .nav-link {
  color: #ffffff;
  font-size: 14px;
}
.overlay .second-para h1 {
  font-size: 18px;
}
.overlay .second-para P {
  font-size: 10px;
}
section.overlay.bg-sec .container {
  padding-top: 0;
  padding-bottom: 0;
}
  .overlay{
    height: 260px !important;
  
  }
  .sec-two {

    padding: 0px 0px;
  }
  .auto_slider_container {
    padding: 15px 0;
  }

.auto_slider li {
  margin: 0 5px;
}
.sec-two .container{
  padding: 0;
}
.sec-two .text-box {
  margin-top: 25px;

}
.sec-two .text-box h2 {
  font-size: 34px;
}
.hoverable-new.hoverable-sec1.aos-init.aos-animate {
  width: 100%;
}
.col-lg-12.d-flex.gap-4.mt-4 {
  flex-direction: column;
}

.hoverable-new .innerbox1 h3 {
  font-size: 28px;

}
.hoverable-new .innerbox1 p{
  font-size: 12px;

}
a.innerbox-checkbox {
  font-size: 12px;
}
.hoverable-new .innerbox1{
  border-top-right-radius:10px;
  padding: 19px 19px;
  margin: 10px;
}
.hoverable-new.hoverable-sec2.aos-init.aos-animate {
  width: 100%;
}
.hoverable-new .innerbox2 p {
  font-size: 12px;

}
.hoverable-new .innerbox1 .innerbox-checkbox-sec .innerbox-checkbox label {
  cursor: pointer;
  font-size: 12px;
}
.hoverable-new .innerbox2{
  border-top-right-radius:10px;
  padding: 19px 19px;
  transform: translate(0px, 0px);

  margin: 10px;
}
.hoverable-new .innerbox2 h3 {
  font-size: 28px;

}
section.sec-three svg {
margin-top: -10px;
}
.service-item .title {
  text-align: center;
}
.service-item .short-description{
  font-size: 11px;
  text-align: center;

}
.service-item .service-links ul{
  list-style: none;
  text-align: center;
  gap: 8px;
    margin-top: 25px !important;
    padding-left: 0 !important;

}
.cta-button {
  text-align: center;
  margin-top: 44px;

}
.hoverable-new .innerbox2 .innerbox-checkbox-sec {
opacity: 1;
gap: 35px !important;
}
.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div h5{
  font-size: 18px !important;
}
.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div h3 {
  font-size: 26px !important;

}
.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div .box-div{
  padding-left:0px !important;
}

.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div p{
  font-size: 14px !important;

}
.portfolio-text-div {
  padding: 20px !important;
}
.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div .box-div {
  margin: 34px 0px !important;
}

.teals-testimonials .container.testmodels {
  flex-direction: column;
}
.teals-testimonials {
  
  padding: 50px 0px 0px !important;
}
.teals-testimonials h2, .teals-testimonials h3 {
  font-size: 30px !important;
}
.testimonial-title p {
  font-size: 12px !important;
}
.teals-testimonials .swiper-button-next {
  width: 50px;
}
.teals-testimonials .swiper-button-next::after {
  font-size: 20px !important;
}
.teals-testimonials .swiper-button-prev {
  width: 50px;
}
.teals-testimonials .swiper-button-prev::after {
  font-size: 20px !important;
}
.hoverable-new:hover .innerbox1, .hoverable-new:hover .innerbox2 {
  border-top-right-radius: 0px;

}
section.Glimpse {

height: 250px;

}

section.Glimpse .text-glips h3 {
  font-size: 20px !important;
  text-align: center !important;
}
.service-item .left .cta-button a {
  padding: 10px 16px;
}
.service-item .left .cta-button a span {
  font-size: 12px !important;
}

.service-item .title {
  font-size: 20px !important;
  font-weight: 600;
}

p.short-description {
  font-size: 11px !important;
  text-align: center;
}

section.Glimpse .button-glips button{
  padding: 6px 16px;
  font-size: 14px !important;

}
.button-glips {
  margin: 0 auto;
}
section.Glimpse {
  height: 240px !important;
}

.industries {
  padding: 3rem 0 0.5rem;
}
.hoverable-new .innerbox2 .innerbox-checkbox-sec {
  height: 230px;
}
.service-links {
  display: none;
}

.industries__title {
  margin-bottom: 1rem !important;
  font-size: 20px !important;
  text-align: center;

}
.industries__text {
  font-size: 14px !important;
  text-align: center;

}
.industries__content {
  margin: 0 auto 2rem;
}
.footer-banner p {
  font-size: 12px !important;
  text-align: center;
}
.container.footer ul {
  margin-bottom: 25px;
  padding: 0;
  gap: 5px !important;
}
.container.footer ul.useful-links li{
  font-size: 8px;
  padding: 2px 7px;
}
.qoute {
 padding-top: 0;
}

.auto_slider li {
  height: 200px;
  width: 200px;
}
.home-services-sec .container {
  padding-bottom: 0;
}

.section-title {
  margin-bottom: 18px;
}
.service-item.bottom .container.d-flex.align-items-center.flex-row-reverse {
padding-top: 0;
}
.service-item.right.bottom .container.d-flex.align-items-center {
  padding-top: 0;
}
.qoute h4 {
  line-height: 2.2rem;

}
.qoute h4 {
  font-size: 20px !important;
}
.heading-text h3 {
margin-bottom: 0;

}
section#price-plans-section .tab-pane{
  margin-top: 0;
}
h2.heading-pricing-colors{
  text-align: center !important;
}
section#price-plans-section .price-list ol li{
  font-size: 14px !important;

}
section#price-plans-section .price-heading-text{
  padding: 15px 0px 4px 0px;
  width: 62%;
  margin: 0 auto;
}
section#price-plans-section .price-heading-text h4{
  font-size: 25px !important;
}

section#price-plans-section .tab-pane{
margin-top: 0 !important;
}
div#myTabContent .container {
  padding-top: 0;
  padding-bottom: 0;
}
h2.heading-pricing-colors span {
  font-size: 30px !important;
}
.testimonials h3 {
  font-size: 25px !important;
}
.container.footer ul.useful-links{
  flex-wrap: wrap !important;
    justify-content: center !important;
}
.container.footer ul.useful-links li{
  font-size: 12px !important;
}
.footer-payment-sec h5{
  font-size: 30px !important;
}
.footer-payment-sec {
  padding: 10px 0px;
}
.footer-payment-sec ul.auto_slider li img {
  width: 60px;
}
.footer-payment-sec.auto_slider li {
  height: 121px;
  width: 122px;
}
.container.footer ul{
  font-size: 15px !important;
}
.full-width-box .menu_container .menu_items .develop{
  display: none;
}
.digitalBanner h2{
  font-size: 40px;
}
.digitalBanner p{
  font-size: 18px;
}
section#home-sec1 .headings-home h1{
  font-size: 34px;
  line-height: 47px;
}
section.designBannerSec .secdesign h3 {
  font-size: 16px;
  margin: auto;
}
section.designBannerSec .secdesign{
  padding: 12px;
}
section.designBannerSec img{
  width: 80%;
}
.firstTextSec{
  width: auto;
}
.firstTextSecght h3{
  font-size: 28px;
}
section.materialDigital{
  padding: 18px 0px;
}
.firstTextSecght p{
  margin-top: 14px;
}
section.materialDigital img {
  width: 70%;
}
}




