.portfolio-text-sec-left {
  padding: 30px 40px;
  /* height: 280px; */
  background: #24e3c5;
  color: white;
  text-align: left;
  border-top-right-radius: 147px;
  position: relative;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  overflow: hidden; /* Ensure text animation works properly */
}
.portfolio-text-sec-left h6 {
  color: #4f4f4f;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Inter';
  transition: opacity 0.5s ease;
  margin-bottom: 0; /* Adjust as needed */
}

.portfolio-text-sec-left h4 {
  font-size: 40px;
  font-weight: 600;
}

.portfolio-text-sec-left:hover h6{
  /* display: none; */
    opacity: 0; /* Fade out h6 when hovering */
}
/* .portfolio-text-sec-left:hover h4{
  display: none;
} */
.portfolio-text-sec-left:hover p{
  display: block;
  font-size: 20px;
}
.animated-text {
  opacity: 0;                 /* Hide paragraph by default */
  max-height: 0;              /* Collapse the height */
  overflow: hidden;           /* Hide overflow content */
  transition: all 0.5s ease;  /* Animation for visibility */
}
.portfolio-text-sec-left:hover .animated-text {
  opacity: 1;                 /* Make paragraph visible */
  max-height: 500px;          /* Expand the height */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.portfolio-img img {
  transition: opacity 0.5s ease-in-out;
}

.portfolio-sec-main-div .portfolio-sec .portfolio-img img {
    object-fit: contain;
    width: 100%;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec-left .portfolio-text-div {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec-left .portfolio-text-div h5 {
    text-align: left;
    font-size: 20px;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec-left .portfolio-text-div h3 {
    text-align: left;
    font-size: 35px;
    font-weight: 700;
    color: #24e3c59e;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec-left .portfolio-text-div p {
    text-align: left;
    font-size: 18px;
    color: #4f4f4f;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec-left .portfolio-text-div .box-div {
    width: 429px;
    position: relative;
    left: -59px;
    background-color: white;
    padding: 25px;
    border-radius: 35px;
    margin: 20px 0;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec-left .portfolio-text-div .box-div h4 {
    color: #24e3c5;
    font-size: 25px;
    text-align: left;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec-left .portfolio-text-div .box-div p {
    font-size: 20px;
    font-style: italic;
    color: #4f4f4f;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec-left .portfolio-text-div .box-div .star-icon-div {
    display: flex;
    align-items: center;
    gap: 0 15px;
    background-color: transparent;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec-left .portfolio-text-div .box-div .star-icon-div .star-icon {
    color: #ffc700;
    font-size: 30px;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec .portfolio-text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: white;
    padding: 21px 0px 0px 21px;
    border-radius: 35px;
    margin: 51px 18px;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec .portfolio-text-div h5 {
    text-align: left;
    font-size: 20px;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec .portfolio-text-div h3 {
    text-align: left;
    font-size: 35px;
    font-weight: 700;
    color: #24e3c59e;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec .portfolio-text-div p {
    text-align: left;
    font-size: 18px;
    color: #4f4f4f;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec .portfolio-text-div .box-div {
    width: 429px;
    position: relative;
    left: -59px;
    background-color: white;
    padding: 25px;
    border-radius: 35px;
    /* margin: 20px 0; */
  }
  
  .portfolio-sec-main-div .portfolio-text-sec .portfolio-text-div .box-div h4 {
    color: #24e3c5;
    font-size: 25px;
    text-align: left;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec .portfolio-text-div .box-div p {
    font-size: 20px;
    font-style: italic;
    color: #4f4f4f;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec .portfolio-text-div .box-div .star-icon-div {
    display: flex;
    align-items: center;
    gap: 0 15px;
    background-color: transparent;
  }
  
  .portfolio-sec-main-div .portfolio-text-sec .portfolio-text-div .box-div .star-icon-div .star-icon {
    color: #ffc700;
    font-size: 30px;
  }
  

  .portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 {
    display: flex;
    flex-direction: column;
    gap: 17px;
}


.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div {
  text-align: left;
  border: none;
  margin: auto;
}

.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div h5 {
  color: #4F4F4F;
  font-size: 20px;
}

.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div h3 {
  color: #24E3C5;
  font-size: 34px;
  font-weight: 700;
}

.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div p {
  color: #4F4F4F;
  font-size: 18px;
  max-height: fit-content;
  opacity: inherit;
}

.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div .box-div {
  padding-left: 22px;
  margin: 42px 0px;
}

.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div .box-div h4 {
  color: #24E3C5;
  font-size: 24px;
}

.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div .box-div p {
  font-style: italic;
}

.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div .box-div .star-icon-div {
  display: flex;
  gap: 1px;
}

.portfolio-sec-main-div section.portfolio-sec .portfolio-text-sec1 .portfolio-text-div .box-div .star-icon-div svg.star-icon {
  color: #FFC700;
  font-size: 30px;
}