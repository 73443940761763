section#price-plans-section {
  position: relative;
  overflow: visible;
  background-size: 100% 100%;
  padding: 30px 0px;
  /* padding-bottom: 70px; */
  background-image: url(../assets/logo-frame.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #f1f8ef;
}
section#price-plans-section .custom-price-button ul ol {
  display: inline-block;
}
section#price-plans-section .fade:not(.show) {
  display: none !important;
}
section#price-plans-section .custom-price-button ul {
  margin: 0;
  padding: 0;
  text-align: center;
}
section#price-plans-section .nav-tabs .nav-link {
  border: 0 !important;
  letter-spacing: 0;
  min-width: auto !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  text-align: center;
  text-transform: capitalize;
  font-family: "Anek Tamil";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 54px;
  color: #4d4d4d;
}
section#price-plans-section ul#myTab {
  padding: 0 0 !important;
  border: 1px solid #4e4e4e;
  border-radius: 18px;
  text-align: center;
  width: 74%;
  margin: 0 auto;
}
.heading-text h3 {
  font-size: 50px;
  color: #24e3c59e;
  margin-bottom: 30px;
}
h2.heading-pricing-colors {
  font-size: 20px;
  text-transform: uppercase;
  text-align: left;
  color:  black;
  border-bottom: 1px solid #24e3c5;
}
h2.heading-pricing-colors span {
  font-size: 45px;
  font-weight: 600;
  color: #24e3c5;
}
section#price-plans-section .price-inner-plan {
  color: #0000;
}
section#price-plans-section .price-heading-text {
  border-radius: 7px;
  text-align: center;
  padding: 10px 0px;
  background-color: #24e3c5;
}

/* section#price-plans-section .price-heading-text h2 {
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: black;
  margin: 20px 0;
} */

section#price-plans-section .price-heading-text h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

section#price-plans-section .price-list ul li::marker {
  color: #24e3c59e !important;
  font-size: 19px;
  line-height: 33px;
}
.d-lg-block {
  display: block !important;
}
section#price-plans-section .price-list ul {
  margin: 0;
  padding-left: 30px;
  height: 246px;
  overflow-y: auto;
}
section#price-plans-section .price-list {
  border-radius: 15px;
  height: 260px;
  overflow-x: hidden;
  margin: 26px 0px;
  border-bottom: 1px solid white;
    background: #ffffff;
    border: 2px solid #24e3c5;
    box-shadow: inset 5px 11px 9px rgba(0, 0, 0, 0.18);
    transition: 0.9s;
    color: #24e3c5;
    padding: 10px;
}
section#price-plans-section .price-list ol li{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: black;
  list-style: circle;
  padding-left: 6px;
  text-align: left;
}
section#price-plans-section .price-inner-plan {
  background: #ffffffa6;
  border: 2px solid #24e3c59e;
  /* box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.13); */
  border-radius: 15px;
  padding: 37px 17px;
}
section#price-plans-section .nav-tabs .nav-link:hover {
  background: #ff10 !important;
}
section#price-plans-section .tab-pane {
  border: 0 !important;
  margin-top: 50px;
  background: none;
}
section#price-plans-section li.nav-item {
  text-align: center;
  margin: 0 auto !important;
  padding: 0 !important;
}

section#price-plans-section .nav-tabs .nav-link.active {
  background: #ff10 !important;
  color: #24e3c59e !important;
}
section#price-plans-section .price-inner-plan:hover  {
  border-color: #24e3c59e;
  transition: 0.9s;
  /* transform: translateY(30px); */
  /* scale: 1.1; */
  background-color: #24e3c5;
}
section#price-plans-section .price-inner-plan:hover h2.heading-pricing-colors{
  border-bottom: 1px solid white;
    background: #ffffff;
    border: 2px solid #24e3c5;
    box-shadow: inset 5px 11px 9px rgba(0, 0, 0, 0.18);
    transition: 0.9s;
    color: #24e3c5;
    padding: 10px;
}
section#price-plans-section .price-inner-plan:hover h2.heading-pricing-colors span{
  color: #24e3c5;
}
section#price-plans-section .price-inner-plan:hover .price-list ol li {
  color: #24E3C5;
}
section#price-plans-section .price-inner-plan:hover  .price-heading-text h4 {
  color: #24e3c5;
}
section#price-plans-section .price-inner-plan:hover .price-heading-text {
  background: #ffffff;
  border: 2px solid #24e3c5;
  box-shadow: inset 5px 11px 9px rgba(0, 0, 0, 0.18);
  transition: 0.9s;
  color: #24e3c5;
}
.custom-price-button button {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    justify-content: center;
    padding: 12px 46px;
    background: #24e3c59e;
    border-radius: 17px;
    box-shadow: 0 0 6px 0px inset rgba(0, 0, 0, 0.4);
    border: none;
    color: white;
}
